<template>
  <div>
    <app-drawer_menu drawer_menu_title="Upload Treaty"></app-drawer_menu>
    <v-tabs background-color="primary" dark color="fourth">
      <v-tab id="tabform" href="#tab-form"> Form </v-tab>
      <v-tab-item value="tab-form">
        <v-progress-linear
          indeterminate
          color="fourth"
          rounded
          v-if="loading.page"
        ></v-progress-linear>
        <!-- Loading Upload -->
        <h4>{{ loading.upload }}</h4>
        <!-- Upload Hidden -->
        <input
          type="file"
          ref="fileupload"
          @change="DisplayFile($event)"
          style="display: none"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        />
        <v-form
          enctype="multipart/form-data"
          ref="form_upload_profile"
          lazy-validation
        >
          <v-row class="mx-2">
            <v-col cols="12" sm="12" md="12">
              <app-cb
                cb_type="campaign"
                cb_url="apiListCampaign"
                cb_url_parameter="campaig_actived=Y"
                cb_title="Campaign"
                cb_id="campaign_id"
                cb_desc="campaign_campaigndesc"
                cb_rules="Please fill Campaign"
                cb_desc_readonly="readonly"
                cb_items_id="campaign_id"
                cb_items_desc="campaign_desc"
                :cb_value_id="form.campaign_id"
                :cb_value_desc="form.campaign_desc"
              >
              </app-cb>
            </v-col>
            <v-col cols="12" sm="9" md="9" class="mt-n10">
              <v-text-field
                :value="form.filename"
                prepend-icon="mdi-file-excel"
                @click="OpenUpload"
                placeholder="Choose File Excel"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3" class="mt-n10">
              <v-select
                v-model="sheetname"
                :items="sheetlist"
                label="Sheet Name"
                prepend-icon="filter_list"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-btn
                color="primary"
                class="white--text"
                rounded
                :disabled="loading.upload === 'Loading...' ? true : false"
                @click="Confirm"
              >
                <v-icon dark>upload</v-icon>Upload
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-tab-item>
    </v-tabs>
    <!-- Dialog -->
    <v-dialog
      v-model="remarks.dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
      max-width="290"
      v-if="remarks.feedback !== ''"
    >
      <v-card>
        <v-toolbar dark color="fourth" dense>
          <v-btn icon dark @click="remarks.dialog = false">
            <v-icon>{{ $vars.V("icon_close") }}</v-icon>
          </v-btn>
          <v-toolbar-title>Error Messages</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-2 ml-n2">
          <div
            class="subheading font-weight-bold"
            v-html="remarks.feedback"
          ></div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Snackbar -->
    <v-snackbar
      v-model="snackbar.dialog"
      :timeout="snackbar.timeout"
      color="fourth"
      rounded="pill"
      top
      style="z-index: 9999"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          @click.native="remarks.dialog = true"
          small
          v-if="snackbar.color === 'error'"
          >Open</v-btn
        >
        <v-btn dark text @click.native="snackbar.dialog = false" small
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-dialog v-model="confirm.dialog" max-width="490" hide-overlay persistent>
      <v-card>
        <v-card-title class="primary white--text"
          >{{ confirm.title }}?</v-card-title
        >
        <v-card-text> </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="dialog"
            color="success"
            :disabled="confirm.text === 'Ok' ? false : true"
            @click="Loading"
            >{{ confirm.text }}</v-btn
          >
          <v-btn color="error" @click="confirm.dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import XLSX from "xlsx";
export default {
  data: () => ({
    snackbar: {
      dialog: false,
      color: "success",
      text: "",
      timeout: 3000,
    },
    confirm: {
      dialog: false,
      title: "",
      text: "Ok",
    },
    loading: {
      page: true,
      upload: "",
      list: false,
      listlogs: false,
    },
    form: {
      filename: "",
      campaign_id: "",
      campaign_desc: "",
    },
    remarks: {
      feedback: "",
      dialog: false,
    },
    headername: [],
    sheetlist: [],
    sheetname: "",
    upload: "",
  }),
  created() {
    this.Access();
  },
  methods: {
    //this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
    Access() {
      let modul = "profile";
      let formdata = {
        menu_id: modul,
        users_id: this.$functions.UsersID(),
        order_by: "menu_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListMenuUser"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let priv = response.data;
          if (priv.length > 0) {
            if (priv[0].usersmenu_add === 0) {
              this.SnackBar(
                true,
                "error",
                this.$functions.NoPriviledge(modul),
                0
              );
            }
          } else {
            this.SnackBar(
              true,
              "error",
              this.$functions.NoPriviledge(modul),
              0
            );
          }
          this.loading.page = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 0);
          this.loading.page = false;
        });
    },
    OpenUpload() {
      this.$refs.fileupload.click();
    },
    DisplayFile(e) {
      const file = e.target.files[0];
      var reader = new FileReader();
      reader.fileName = file.name;
      let get = this;
      get.sheetlist = [];
      get.sheetname = "";
      get.loading.upload = "Loading...";
      reader.onload = function (e) {
        var data = e.target.result;
        var workbook = XLSX.read(data, { type: "binary" });
        get.sheetlist = workbook.SheetNames;
        get.form.filename = e.target.fileName;
        get.loading.upload = e.target.fileName;
      };
      this.upload = file;
      reader.readAsBinaryString(file);
    },
    //Pop Up message for validating (Ok or Cancel) your proccess
    Confirm() {
      this.confirm.dialog = true;
      this.confirm.title = "Upload";
      setTimeout(function () {
        document.getElementById("dialog").focus();
      }, 500);
    },
    Loading() {
      this.confirm.text = "Loading...";
      this.Upload();
    },
    Upload() {
      let form = new FormData();
      let campaignid = document.getElementById("campaign_id").value;
      form.append("sheetname", this.sheetname);
      form.append("uploadfile", this.upload);
      form.append("campaign", campaignid);
      this.$axios
        .post(this.$functions.SafeURL("apiUploadPolicy", ``), form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (response.data[0].feedback === "Y") {
              this.SnackBar(true, "success", "Upload Success", 0);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              this.loading.upload = "";
              this.form.filename = "";
              this.sheetname = "";
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    SnackBar(dialog, color, text, timeout) {
      this.snackbar = {
        dialog: dialog,
        color: color,
        text: text,
        timeout: timeout,
      };
      if (color === "error") {
        this.confirm.dialog = false;
        this.confirm.text = "Ok";
      }
    },
  },
};
</script>
