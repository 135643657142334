<template>
  <v-text-field
    v-model="formattedValue"
    :label="label"
    @input="onInput"
    @blur="onBlur"
    backgroundColor="numeric"
    class="money-input"
  ></v-text-field>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "IDR",
    },
    value: {
      type: [String, Number],
      default: "0.000000000000000",
    },
    decimal: {
      type: Number,
      default: 15,
    },
  },
  data() {
    return {
      formattedValue: String(this.value),
    };
  },
  watch: {
    value: {
      handler(newValue) {
        this.formattedvalue = newValue;
      },
      immediate: true, // agar langsung dijalankan saat komponen dimuat
    },
  },
  mounted() {
    this.formattedValue = this.formatNumber(String(this.value));
  },
  methods: {
    formatNumber(value) {
      value = String(value);
      const [integerPart, decimalPart = ""] = value.split(".");
      const cleanedInteger = integerPart.replace(/\D/g, "");
      const formattedInteger = Number(cleanedInteger).toLocaleString("en-US");
      const cleanedDecimal = decimalPart
        .replace(/\D/g, "")
        .slice(0, this.decimal);
      const paddedDecimal = cleanedDecimal.padEnd(this.decimal, "0");
      return `${formattedInteger}.${paddedDecimal}`;
    },
    onInput(value) {
      value = String(value);
      this.formattedValue = value;
      const [integerPart, decimalPart = ""] = value.split(".");
      const cleanedInteger = integerPart.replace(/\D/g, "");
      const cleanedDecimal = decimalPart
        .replace(/\D/g, "")
        .slice(0, this.decimal);
      this.$emit("input", `${cleanedInteger}.${cleanedDecimal || "0"}`);
    },
    onBlur() {
      this.formattedValue = this.formatNumber(this.value);
    },
  },
};
</script>

<style scoped>
.money-input ::v-deep .v-text-field__slot input {
  text-align: right !important;
  font-family: monospace !important;
}
</style>
