<template>
  <v-dialog
    v-model="dialogprint"
    persistent
    transition="dialog-bottom-transition"
    width="690"
  >
    <v-card>
      <v-toolbar dark color="primary" dense v-if="modul === 'acceptance'">
        <v-btn icon dark @click.stop="dialogprint = false">
          <v-icon>cancel</v-icon>
        </v-btn>
        <v-toolbar-title>Print - {{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="Open" title="Print"
          ><v-icon color="white">print</v-icon></v-btn
        >
      </v-toolbar>
      <v-toolbar dark color="primary" dense v-if="modul === 'facultative'">
        <v-toolbar-title
          >Select Reinsurance (Print {{ title }})</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn icon dark @click.stop="dialogprint = false">
          <v-icon>cancel</v-icon>
        </v-btn>
      </v-toolbar>
      <template v-if="modul === 'acceptance'">
        <v-form
          enctype="multipart/form-data"
          ref="print_quotation"
          lazy-validation
          onSubmit="return false"
        >
          <v-row class="mx-2">
            <v-col cols="12" sm="12" md="3">
              <v-radio-group v-model="print.language">
                <template>
                  <div>Choose Language:</div>
                </template>
                <v-radio label="Indonesian" value="IND"> </v-radio>
                <v-radio label="English" value="ENG"> </v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <v-radio-group v-model="print.detail">
                <template>
                  <div>Choose Detail:</div>
                </template>
                <v-radio label="No" value="N"> </v-radio>
                <v-radio label="Yes" value="Y"> </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template v-if="modul === 'facultative'">
        <v-progress-linear
          indeterminate
          color="fourth"
          rounded
          v-if="loading.detail"
        ></v-progress-linear>
        <v-list dense>
          <v-list-item-group>
            <template v-for="(reins, index) in reinstans">
              <v-list-item :key="reins.reinstrans_profileid">
                <template>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="reins.reinstrans_profiledesc"
                    ></v-list-item-title>

                    <v-list-item-subtitle
                      v-text="reins.reinstrans_profileid"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-spacer></v-spacer>
                  <v-list-item-avatar
                    @click="Open(reins.reinstrans_docno, modul, id, reins.text)"
                  >
                    <v-icon color="#00000">print</v-icon>
                  </v-list-item-avatar>
                </template>
              </v-list-item>

              <v-divider
                v-if="index < items.length - 1"
                :key="index"
              ></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
      </template>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    value: Boolean,
    modul: String,
    id: String,
    title: String,
    doc: String,
  },
  data: () => ({
    url_type: "add",
    items: [],
    reinstans: [],
    print: {
      language: "IND",
      detail: "N",
    },
    loading: {
      detail: false,
    },
  }),
  computed: {
    dialogprint: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    dialogprint: function (newdata) {
      if (newdata === true) {
        if (this.modul === "facultative") {
          this.reinstans = [];
          this.GetReinsurance(this.id);
        } else if (this.modul === "quotation") {
          if (this.refid === "U") {
            this.items = [
              {
                doc: "public_offering",
                text: "Quotation General",
                icon: "description",
                subtitle: "Surat Penawaran Umum",
              },
            ];
          } else if (this.refid === "K") {
            this.items = [
              {
                doc: "special_offering",
                text: "Quotation Special",
                icon: "description",
                subtitle: "Surat Penawaran Khusus",
              },
            ];
          }
          if (this.items.length === 1) {
            this.Open(
              this.items[0].doc,
              this.modul,
              this.id,
              this.items[0].text
            );
          }
        }
      }
    },
  },
  methods: {
    Open() {
      let document = "doc_" + this.doc + ".php";
      let url =
        this.$functions.AppDoc() +
        "/modul/mod_documents/" +
        document +
        "?document=" +
        this.doc +
        "&login=" +
        this.$functions.UsersID() +
        "&modul=" +
        this.modul +
        "&id=" +
        this.id +
        "&language=" +
        this.print.language +
        "&detail=" +
        this.print.detail;
      this.$functions.OpenURL(url);
    },
    GetReinsurance(docno) {
      if (docno !== "") {
        this.loading.detail = true;
        let formdata = {
          reinstrans_docno: docno,
        };
        let param = this.$functions.ParamPOST(formdata);
        this.$axios
          .post(this.$functions.UrlPOST("apiListReinsTrans"), param, {
            headers: {
              "Content-Type": "text/plain; charset=ISO-8859-1",
            },
          })
          .then((response) => {
            let feedback = response.data;
            if (feedback.length > 0) {
              if (feedback[0].feedback === "Y") {
                this.reinstans = feedback;
              } else {
                this.SnackBar(true, "error", feedback[0].feedback, "3000");
              }
            } else {
              this.SnackBar(true, "error", this.$functions.NoData(), "3000");
            }
            this.loading.detail = false;
          })
          .catch((e) => {
            this.SnackBar(true, "error", e, 3000);
            this.loading.detail = false;
          });
      }
    },
  },
};
</script>
