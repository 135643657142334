<template>
  <div id="app-attachment">
    <v-progress-linear
      indeterminate
      color="fourth"
      rounded
      v-if="loading.page"
    ></v-progress-linear>
    <div v-if="access.read === 0" class="text-center font-weight-black">
      {{ $vars.V("txt_no_access") }}
    </div>
    <div v-else>
      <v-card-title class="ml-n4">
        <v-btn
          :color="selected.length === items.length ? 'fourth' : ''"
          @click="SelectAllPage"
          class="mr-2"
          title="select all page"
          small
          >{{ $vars.V("txt_select_all_page") }}
        </v-btn>
        <v-btn
          color="error"
          title="delete selected"
          @click="Confirm('multidelete', '')"
          class="mr-2"
          v-if="selected.length > 0"
          :disabled="access.delete === 0 ? true : false"
          small
        >
          <v-icon>{{ $vars.V("icon_delete") }}</v-icon>
        </v-btn>
        <v-btn @click="Add" class="mr-2" title="Add New" small
          >{{ $vars.V("txt_add") }}
        </v-btn>
        <v-btn @click="List(id)" class="mr-2" title="Refresh" small
          ><v-icon>{{ $vars.V("icon_refresh") }}</v-icon>
        </v-btn>
        <v-btn
          color="primary"
          title="search"
          v-if="btn_search === false"
          @click="btn_search = true"
          small
        >
          <v-icon>{{ $vars.V("icon_search") }}</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          @click:append="btn_search = false"
          label="Search"
          single-line
          hide-details
          v-if="btn_search"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :value="selected"
        :headers="headers"
        :items="items"
        :search="search"
        @input="Check($event)"
        item-key="attachment_id"
        show-select
        class="elevation-1"
        :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'first_page',
          lastIcon: 'last_page',
          prevIcon: 'keyboard_arrow_left',
          nextIcon: 'keyboard_arrow_right',
        }"
        dense
        :loading="loading.detail"
        loading-text="Loading... Please wait"
      >
        <template v-slot:item.attachment_path="{ item }">
          <v-icon @click="$functions.OpenURL(item.attachment_path_url)"
            >download</v-icon
          >
        </template>
        <template v-slot:item.action="{ item }">
          <v-icon
            small
            @click="Edit(item.attachment_id)"
            :disabled="access.edit === 0 ? true : false"
            title="edit"
          >
            {{ $vars.V("icon_edit") }}
          </v-icon>
        </template>
      </v-data-table>
    </div>
    <!-- Start Form -->
    <v-dialog
      v-model="dialog.detail"
      max-width="800"
      transition="dialog-transition"
      persistent
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="dialog.detail = false">
            <v-icon>{{ $vars.V("icon_close") }}</v-icon>
          </v-btn>
          <v-toolbar-title>Attachment</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="Add" title="Add New"
            ><v-icon color="white">add</v-icon></v-btn
          >
          <v-btn icon dark @click="Confirm(url_type, '')" title="Save"
            ><v-icon color="white">save</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-form
            enctype="multipart/form-data"
            ref="form_attachment"
            lazy-validation
          >
            <v-row class="mx-auto">
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  v-model="form.attachment_title"
                  required
                  label="Document No"
                  placeholder="Document No"
                  id="attachment_title"
                  :rules="form.attachment_title_rules"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4" class="mt-4">
                <v-select
                  v-model="form.attachment_type"
                  :items="detail.attachmenttype"
                  label="Type"
                  dense
                  undelined
                  item-value="code_id"
                  item-text="code_desc"
                  return-object
                ></v-select>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  v-model="form.attachment_remarks"
                  required
                  label="Remarks"
                  placeholder="Remarks"
                  id="attachment_remarks"
                  :rules="form.attachment_remarks_rules"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="10" md="10">
                <v-card
                  class="secondary text-center cursor"
                  width="500"
                  height="150"
                  @click="UploadPath"
                  :img="form.attachment_path_url"
                >
                  <div class="caption primary mb-3 white--text">Attachment</div>
                  <div>{{ form.attachment_path_name }}</div>
                  <input
                    type="file"
                    ref="attachment_path"
                    @change="UploadPathURL"
                    accept=".jpg, .jpeg, .png, .pdf"
                    style="display: none"
                  />
                </v-card>
              </v-col>
              <!-- Active -->
              <v-col cols="2" sm="2" md="2" class="mt-n5">
                <label class="caption">Active Status</label>
                <v-switch
                  v-model="form.attachment_actived"
                  color="primary"
                  id="form.attachment_actived"
                  :label="form.attachment_actived !== true ? '' : 'Active'"
                ></v-switch>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.dialog"
      :timeout="snackbar.timeout"
      color="fourth"
      rounded="pill"
      top
      absolute
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          @click.native="remarks.dialog = true"
          small
          v-if="snackbar.color === 'error'"
          >Open</v-btn
        >
        <v-btn dark text @click.native="snackbar.dialog = false" small
          >Close</v-btn
        >
      </template>
    </v-snackbar>

    <v-dialog
      v-model="confirm.dialog"
      :max-width="Variable('confirm', 0)"
      persistent
    >
      <v-card :color="Variable('confirm', 4)">
        <v-card-title :class="Variable('confirm', 3)"
          >{{ confirm.title }}?</v-card-title
        >
        <v-card-text></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="dialog"
            :color="Variable('confirm', 1)"
            :disabled="confirm.text === 'Ok' ? false : true"
            @click="Loading"
          >
            {{ confirm.text }}
          </v-btn>
          <v-btn :color="Variable('confirm', 2)" @click="confirm.dialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="remarks.dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
      max-width="290"
      v-if="remarks.feedback !== ''"
    >
      <v-card>
        <v-toolbar dark color="fourth" dense>
          <v-btn icon dark @click="remarks.dialog = false">
            <v-icon>{{ $vars.V("icon_close") }}</v-icon>
          </v-btn>
          <v-toolbar-title>Error Messages</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-2 ml-n2">
          <div
            class="subheading font-weight-bold"
            v-html="remarks.feedback"
          ></div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- End Organization -->
  </div>
</template>
<script>
export default {
  props: {
    modul: String,
    refno: String,
  },
  data: () => ({
    url_type: "add",
    access: {
      read: 0,
      add: 0,
      edit: 0,
      delete: 0,
    },
    remarks: {
      feedback: "",
      dialog: false,
    },
    snackbar: {
      dialog: false,
      color: "success",
      text: "",
      timeout: 3000,
    },
    confirm: {
      dialog: false,
      title: "",
      text: "Ok",
    },
    loading: {
      page: true,
      detail: true,
    },
    dialog: {
      detail: false,
    },
    formatamount: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 15,
      precision: 0,
    },
    formatrate: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 5,
      precision: 2,
    },
    properties: {
      reverse: true,
    },
    selected: [],
    items: [],
    btn_search: false,
    search: "",
    headers: [
      { text: "ID", value: "attachment_id" },
      { text: "Type", value: "attachment_type_desc" },
      { text: "Docno", value: "attachment_title" },
      { text: "Ext", value: "attachment_ext" },
      { text: "Path", value: "attachment_path" },
      { text: "Remarks", value: "attachment_remarks" },
      { text: "Actions", value: "action", sortable: false },
    ],
    form: {
      attachment_id: "",
      attachment_modul: "",
      attachment_refno: "",
      attachment_title: "",
      attachment_title_rules: [(v) => !!v || "Please fill Document No"],
      attachment_remarks: "",
      attachment_remarks_rules: [(v) => !!v || "Please fill Remarks"],
      attachment_ext: "",
      attachment_path: "",
      attachment_path_url: "",
      attachment_path_name: "",
      attachment_type: "",
      attachment_actived: true,
    },
    detail: {
      attachmenttype: [],
    },
  }),
  created() {
    this.List(this.refno, this.modul);
  },
  methods: {
    Access() {
      let modul = "attachment";
      let formdata = {
        menu_id: modul,
        users_id: this.$functions.UsersID(),
        order_by: "menu_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListMenuUser"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let priv = response.data;
          if (priv.length > 0) {
            this.access.read = priv[0].usersmenu_read;
            this.access.add = priv[0].usersmenu_add;
            this.access.edit = priv[0].usersmenu_edit;
            this.access.delete = priv[0].usersmenu_delete;
            if (priv[0].usersmenu_read === 0) {
              this.SnackBar(
                true,
                "error",
                this.$functions.NoPriviledge(modul),
                0
              );
            }
          } else {
            this.SnackBar(
              true,
              "error",
              this.$functions.NoPriviledge(modul),
              0
            );
          }
          this.loading.page = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 0);
          this.loading.page = false;
        });
    },
    SnackBar(dialog, color, text, timeout) {
      this.snackbar = {
        dialog: dialog,
        color: color,
        text: text,
        timeout: timeout,
      };
      if (color === "error") {
        this.confirm.dialog = false;
        this.confirm.text = "Ok";
      }
    },
    Variable(flag, position) {
      return this.$functions.Variable(flag, position);
    },
    SelectAllPage() {
      this.selected = this.selected === this.items ? [] : this.items;
    },
    Check(value) {
      this.selected = value;
    },
    Loading() {
      this.confirm.text = "Loading...";
      if (this.url_type === "add" || this.url_type === "edit") {
        this.Write();
      } else if (this.url_type === "delete") {
        this.Delete(this.form.attachment_id);
      } else if (this.url_type === "multidelete") {
        this.MultiProcess("delete");
      }
    },
    Write() {
      let formdata = {
        url_type: this.url_type,
        attachment_refno: this.refno,
        attachment_modul: this.modul,
        attachment_title: this.form.attachment_title,
        attachment_type: this.form.attachment_type.code_id,
        attachment_remarks: this.form.attachment_remarks,
        attachment_path: this.form.attachment_path,
        attachment_path_url: this.form.attachment_path_url.replace(
          /^.+?;base64,/,
          ""
        ),
        attachment_actived: this.$functions.ActivedFlag(
          this.form.attachment_actived
        ),
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteAttachment"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Save Success", 3000);
              this.dialog.detail = false;
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              if (this.url_type === "add") {
                this.form.attachment_cuser = feedback[0].feedback_users_id;
                this.form.attachment_cdate = feedback[0].feedback_users_date;
              }
              this.List(this.refno);
              this.form.attachment_luser = feedback[0].feedback_users_id;
              this.form.attachment_ldate = feedback[0].feedback_users_date;
              this.url_type = "edit";
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    Delete(id) {
      let formdata = {
        url_type: "delete",
        attachment_id: id,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteAttachment"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Delete Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              this.List(this.refno);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
          this.confirm.dialog = false;
          this.confirm.text = "Ok";
        });
    },
    Add() {
      this.AttachmentType();
      this.dialog.detail = true;
      this.url_type = "add";
      this.form.attachment_title = "";
      this.form.attachment_remarks = "";
      setTimeout(function () {
        document.getElementById("attachment_title").focus();
      }, 500);
    },
    Edit(id) {
      if (id !== "") {
        this.Add();
        this.loading.detail = true;
        let formdata = {
          attachment_id: id,
        };
        let param = this.$functions.ParamPOST(formdata);
        this.$axios
          .post(this.$functions.UrlPOST("apiListAttachment"), param, {
            headers: {
              "Content-Type": "text/plain; charset=ISO-8859-1",
            },
          })
          .then((response) => {
            let feedback = response.data;
            if (feedback.length > 0) {
              if (feedback[0].feedback === "Y") {
                this.dialog.detail = true;
                this.url_type = "edit";
                this.form.attachment_modul = feedback[0].attachment_modul;
                this.form.attachment_refno = feedback[0].attachment_refno;
                this.form.attachment_ext = feedback[0].attachment_ext;
                this.form.attachment_type = feedback[0].attachment_type;
                this.form.attachment_title = feedback[0].attachment_title;
                this.form.attachment_remarks = feedback[0].attachment_remarks;
                this.form.attachment_path = feedback[0].attachment_path;
                this.form.attachment_path_url = feedback[0].attachment_path_url;
                this.form.attachment_actived = this.$functions.TrueOrFalse(
                  feedback[0].attachment_actived
                );
              } else {
                this.SnackBar(true, "error", feedback[0].feedback, "3000");
              }
            } else {
              this.SnackBar(true, "error", this.$functions.NoData(), "3000");
            }
            this.loading.detail = false;
          })
          .catch((e) => {
            this.SnackBar(true, "error", e, 3000);
            this.loading.detail = false;
          });
      }
    },
    Confirm(flag, id) {
      if (flag === "add" || flag === "edit") {
        if (this.$refs.form_attachment.validate()) {
          this.confirm.dialog = true;
          this.confirm.title = "Save";
          this.url_type = "add";
          setTimeout(function () {
            document.getElementById("dialog").focus();
          }, 500);
        }
      } else if (flag === "delete") {
        this.url_type = "delete";
        this.confirm.dialog = true;
        this.confirm.title = "Delete `" + id + "`";
        this.form.attachment_id = id;
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "multidelete") {
        this.url_type = "multidelete";
        this.confirm.dialog = true;
        this.confirm.title = "Delete `" + this.selected.length + "` data";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      }
    },
    List(id, modul) {
      this.Access();
      this.items = [];
      if (id !== "") {
        this.loading.detail = true;
        let formdata = {
          attachment_refno: id,
          attachment_modul: modul,
          order_by: "attachment_refno",
          order_type: "ASC",
          limit: this.limit,
        };
        let param = this.$functions.ParamPOST(formdata);
        this.$axios
          .post(this.$functions.UrlPOST("apiListAttachment"), param, {
            headers: {
              "Content-Type": "text/plain; charset=ISO-8859-1",
            },
          })
          .then((response) => {
            let feedback = response.data;
            if (feedback.length > 0) {
              if (feedback[0].feedback !== "Y") {
                this.SnackBar(true, "error", feedback[0].feedback, 0);
              } else {
                this.items = feedback;
              }
            } else {
              this.items = feedback;
              // this.SnackBar(true, 'error', this.$functions.NoData(), 0)
            }
            this.loading.detail = false;
          })
          .catch((e) => {
            this.SnackBar(true, "error", e, 3000);
            this.loading.detail = false;
          });
      }
    },
    UploadPath() {
      this.$refs.attachment_path.click();
    },
    UploadPathURL() {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.form.attachment_path_url = e.target.result;
          this.form.attachment_path = e.target.result;
        };
        this.form.attachment_path_name = input.files[0].name;
        reader.readAsDataURL(input.files[0]);
      }
    },
    AttachmentType() {
      let formdata = {
        code_group: "DOCUMENT",
        code_actived: "Y",
        order_by: "code_desc",
        order_type: "ASC",
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListCode"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.detail.attachmenttype = feedback;
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
  },
};
</script>
