<template>
  <div id="app-logs">
    <!-- Header -->

    <v-toolbar flat dense class="px-0">
      <v-btn icon>
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-toolbar-title>Log Data</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field
        outlined
        dense
        prepend-inner-icon="mdi-magnify"
        label="Search log"
        class="search-field"
        v-model="searchQuery"
        hide-details
      ></v-text-field>
    </v-toolbar>
    <v-progress-linear
      indeterminate
      color="fourth"
      rounded
      v-if="loading.log"
    ></v-progress-linear>

    <!-- Main Content -->
    <v-row class="mt-0">
      <!-- Sidebar (Email List) -->
      <v-col md="4" cols="12">
        <v-card outlined>
          <v-list two-line>
            <v-list-item-group
              v-model="selected.list"
              active-class="selected-item"
            >
              <template v-for="(item, index) in filteredItems">
                <v-list-item
                  :key="item.ticketlog_id"
                  class="mail-item"
                  :class="{ unread: !item.isRead }"
                >
                  <template v-slot:default="{ active }">
                    <!-- Left Actions -->
                    <v-list-item-action class="mr-2">
                      <v-checkbox v-model="active" color="primary"></v-checkbox>
                    </v-list-item-action>

                    <!-- Content -->
                    <v-list-item-content>
                      <div class="d-flex align-center">
                        <v-list-item-title
                          :class="{ 'font-weight-bold': !item.isRead }"
                          class="sender-name"
                        >
                          {{ item.ticketlog_cusername }}
                        </v-list-item-title>
                      </div>

                      <v-list-item-subtitle
                        class="text--primary font-weight-medium"
                        :class="{ 'font-weight-bold': !item.isRead }"
                      >
                        {{ item.ticketlog_subject }}
                      </v-list-item-subtitle>

                      <v-list-item-subtitle class="message-preview">
                        <span v-html="truncateBody(item.ticketlog_body)"></span>
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <!-- Right Actions -->
                    <v-list-item-action>
                      <span class="timeago">
                        {{ $functions.TimeAgo(item.ticketlog_cdate) }}</span
                      >
                      <v-icon
                        :color="
                          item.isStarred ? 'yellow darken-2' : 'grey lighten-1'
                        "
                        @click.stop="toggleStar(item)"
                        class="star-icon"
                      >
                        {{ item.isStarred ? "mdi-star" : "mdi-star-outline" }}
                      </v-icon>
                    </v-list-item-action>
                  </template>
                </v-list-item>

                <v-divider
                  v-if="index < filteredItems.length - 1"
                  :key="index"
                ></v-divider>
              </template>

              <v-card-text
                v-if="filteredItems.length == 0"
                class="text-center grey--text"
              >
                No data found
              </v-card-text>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>

      <!-- Email Detail -->
      <v-col md="8" cols="12">
        <transition name="slide-fade">
          <v-card
            outlined
            v-if="selectedItem.ticketlog_recipient"
            key="email-detail"
          >
            <v-card-title>
              <v-list-item>
                <v-list-item-avatar>
                  <v-img src="no-image.png"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{
                    selectedItem.ticketlog_recipient
                  }}</v-list-item-title>
                  <v-list-item-subtitle>Recipient</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <span class="timeago">{{
                    $functions.FormatDateTime(selectedItem.ticketlog_cdate)
                  }}</span>
                </v-list-item-action>
              </v-list-item>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <div v-html="selectedItem.ticketlog_body"></div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="attachment-section">
              <v-container fluid class="pa-0">
                <div class="d-flex align-center mb-3">
                  <v-icon color="grey darken-1" class="mr-2"
                    >mdi-paperclip</v-icon
                  >
                  <span class="text-subtitle-1 grey--text text--darken-2">
                    {{ attachments.length }} Attachment{{
                      attachments.length !== 1 ? "s" : ""
                    }}
                  </span>
                </div>

                <v-row v-if="attachments.length > 0">
                  <v-col
                    v-for="(attachment, index) in attachments"
                    :key="index"
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-card outlined class="attachment-card" hover>
                      <v-card-text class="pa-3">
                        <div class="d-flex align-center">
                          <v-icon
                            :color="getFileIconColor(attachment.attachment_ext)"
                            class="mr-3"
                            size="32"
                          >
                            {{ getFileIcon(attachment.attachment_ext) }}
                          </v-icon>

                          <div class="attachment-info">
                            <div class="attachment-name">
                              {{ attachment.attachment_title }}
                            </div>
                            <div class="attachment-meta">
                              {{ formatFileSize(attachment.attachment_size) }}
                            </div>
                          </div>

                          <v-spacer></v-spacer>

                          <v-btn
                            icon
                            small
                            class="download-btn"
                            @click="
                              $functions.OpenURL(attachment.attachment_path_url)
                            "
                          >
                            <v-icon size="20">mdi-download</v-icon>
                          </v-btn>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <div v-else class="no-attachments">
                  <v-icon color="grey lighten-1" large
                    >mdi-cloud-off-outline</v-icon
                  >
                  <span class="grey--text">No attachments</span>
                </div>
              </v-container>
            </v-card-actions>
          </v-card>
          <v-card v-else key="no-email" outlined>
            <v-card-text class="text-center grey--text">
              Select an email to read
            </v-card-text>
          </v-card>
        </transition>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    modul: String,
    ticket_id: String,
  },
  data: () => ({
    searchQuery: "",
    fallbackImage: "/no-image.png",
    selected: {
      list: null,
    },
    items: [],
    attachments: [],
    loading: {
      log: true,
    },
    snackbar: {
      dialog: false,
      color: "success",
      text: "",
      timeout: 3000,
    },
  }),
  computed: {
    filteredItems() {
      const query = this.searchQuery.toLowerCase().trim();
      if (!query) return this.items;
      return this.items.filter((item) => {
        return (
          item.ticketlog_cusername.toLowerCase().includes(query) ||
          item.ticketlog_recipient.toLowerCase().includes(query) ||
          item.ticketlog_subject.toLowerCase().includes(query) ||
          item.ticketlog_body.toLowerCase().includes(query)
        );
      });
    },
    selectedItem() {
      if (this.filteredItems.length === 0 || this.selected.list === null) {
        return {
          ticketlog_cusername: "",
          ticketlog_recipient: "",
          ticketlog_body: "",
          ticketlog_users_avatarurl: "",
        };
      }
      return this.filteredItems[this.selected.list] || {};
    },
  },
  created() {
    this.GetLog(this.ticket_id);
    this.GetListAttachment(this.ticket_id);
  },
  watch: {
    ticket_id(newVal) {
      this.GetLog(newVal);
      this.GetListAttachment(newVal);
    },
  },
  methods: {
    SnackBar(dialog, color, text, timeout) {
      this.snackbar = {
        dialog: dialog,
        color: color,
        text: text,
        timeout: timeout,
      };
    },
    handleImageError(event) {
      if (event.target && event.target.tagName.toLowerCase() === "img") {
        event.target.src = this.fallbackImage;
      }
    },
    truncateBody(text) {
      const plainText = text.replace(/<[^>]*>/g, "");
      return plainText.length > 100
        ? plainText.slice(0, 100) + "..."
        : plainText;
    },
    toggleStar(item) {
      item.isStarred = !item.isStarred;
    },
    getFileIcon(type) {
      const icons = {
        png: "mdi-file-image",
        jpg: "mdi-file-image",
        jpeg: "mdi-file-image",
        pdf: "mdi-file-pdf-box",
        doc: "mdi-file-word",
        docx: "mdi-file-word",
        xls: "mdi-file-excel",
        xlsx: "mdi-file-excel",
        zip: "mdi-folder-zip",
        default: "mdi-file-document-outline",
      };
      return icons[type] || icons.default;
    },
    getFileIconColor(type) {
      const colors = {
        image: "blue lighten-1",
        jpg: "blue lighten-1",
        jpeg: "blue lighten-1",
        pdf: "red darken-1",
        doc: "blue darken-1",
        docx: "blue darken-1",
        xls: "green darken-1",
        xlsx: "green darken-1",
        zip: "brown lighten-1",
        default: "grey darken-1",
      };
      return colors[type] || colors.default;
    },
    formatFileSize(bytes) {
      if (bytes === 0) return "0 Bytes";
      const k = 1024;
      const sizes = ["Bytes", "KB", "MB", "GB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
    },
    GetListAttachment(value) {
      let formdata = {
        attachment_refno: value,
        order_by: "attachment_id",
        order_type: "ASC",
        limit: "1000",
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListAttachment"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              this.attachments = feedback;
            }
          } else {
            this.SnackBar(true, "error", this.$functions.NoData(), 0);
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
        });
    },
    GetLog(ticket_id) {
      this.loading.log = true;
      let formdata = {
        ticketlog_ticketid: ticket_id,
        order_by: "ticketlog_cdate",
        order_type: "DESC",
        limit: "1000",
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListTicketLog"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
              this.items = [];
            } else {
              this.items = feedback.map((item) => ({
                ...item,
                isRead: false,
                isStarred: false,
              }));
            }
            this.loading.log = false;
          } else {
            this.SnackBar(true, "error", this.$functions.NoData(), 0);
            this.items = [];
          }
        });
    },
  },
};
</script>
<style scoped>
#app-logs {
  height: 100vh;
  overflow: hidden;
}

.v-toolbar {
  background-color: #f5f5f5;
  border-bottom: 1px solid #e0e0e0;
}

.search-field {
  max-width: 50%;
}

.mail-item {
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s;
}

.mail-item:hover {
  background-color: #f5f5f5;
  transform: translateX(5px);
}

.selected-item {
  background-color: #e3f2fd !important;
}

.unread {
  background-color: #f2f6fc;
}

.sender-name {
  font-size: 14px;
}

.message-preview {
  font-size: 12px;
  color: #5f6368;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.timeago {
  font-size: 10px;
  color: #5f6368;
}

.v-card-text {
  font-size: 14px;
  line-height: 1.6;
}

.star-icon {
  transition: transform 0.2s, color 0.2s;
}

.star-icon:hover {
  transform: scale(1.2);
}

/* Animasi untuk detail email */
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateX(20px);
}
.attachment-section {
  display: block !important;
  padding: 16px;
}

.attachment-card {
  transition: all 0.2s ease;
  border: 1px solid #e0e0e0 !important;
}

.attachment-card:hover {
  background-color: #f5f5f5;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302),
    0 1px 3px 1px rgba(60, 64, 67, 0.149) !important;
}

.attachment-info {
  min-width: 75%;
  flex: 1;
}

.attachment-name {
  font-size: 0.875rem;
  font-weight: 500;
  color: #202124;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.attachment-meta {
  font-size: 0.75rem;
  color: #5f6368;
  margin-top: 2px;
}

.download-btn {
  opacity: 0;
  transition: opacity 0.2s ease;
}

.attachment-card:hover .download-btn {
  opacity: 1;
  color: #2196f3;
}

.no-attachments {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px;
  color: #5f6368;
}
</style>
