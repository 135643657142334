<!-- 
Drawer Menu
author: riva.ananta
-->
<template>
  <div id="toolbarhome">
    <!-- Dialog Main Menu -->
    <v-dialog
      v-model="dialog_module"
      scrollable
      fullscreen
      persistent
      :overlay="false"
      transition="dialog-bottom-transition"
    >
      <v-card color="white">
        <v-card-title class="primary--text">
          <v-icon @click="dialog_module = false" color="primary">{{
            $vars.V("icon_back")
          }}</v-icon
          >ASRINDA
          <v-spacer></v-spacer>
          <v-icon small icon @click="GetModules" class="mr-3 primary--text">{{
            $vars.V("icon_refresh")
          }}</v-icon>
          <v-icon small icon @click="FullScreen" class="primary--text">{{
            $vars.V("icon_fullscreen")
          }}</v-icon>
          <v-icon small class="mx-3 primary--text">{{
            $vars.V("icon_notification")
          }}</v-icon>
          <!-- If Click Open Search Text -->
          <v-icon
            small
            class="primary--text"
            @click="form.search = !form.search"
            v-if="form.search === false"
            >search</v-icon
          >
          <v-icon
            small
            class="primary--text"
            @click="form.search = !form.search"
            v-else
            >cancel</v-icon
          >
        </v-card-title>

        <v-card-text>
          <v-container class="text-center">
            <v-progress-linear
              indeterminate
              color="fourth"
              rounded
              v-if="loading.module"
            ></v-progress-linear>
            <!-- Search Menu -->
            <template v-if="form.search">
              <v-text-field
                v-model="form.search_text"
                append-icon="search"
                label="Search Menu"
                single-line
                hide-details
                filled
                clearable
                class="white"
                @keyup.enter="SearchMenu()"
              ></v-text-field>
              <v-progress-linear
                indeterminate
                color="fourth"
                rounded
                v-if="loading.search"
              ></v-progress-linear>
              <v-list dense v-if="itemssearch.length > 0">
                <v-list-item
                  v-for="rows in itemssearch"
                  :key="rows.menu_id"
                  link
                >
                  <v-list-item-content @click="GoTo(rows.menu_id)">
                    <v-list-item-title>
                      {{ rows.menu_desc }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </template>
            <template v-else>
              <v-row no-gutters>
                <v-col
                  v-for="data in modules"
                  :key="data.usersmodul_modulid"
                  cols="4"
                  sm="4"
                >
                  <v-card
                    class="mx-2 ma-2"
                    :color="data.usersmodul_modulcolour"
                    flat
                    tile
                    outlined
                    @click="GoTo(data.usersmodul_modulurl, undefined)"
                  >
                    <v-list dense>
                      <v-list-item-group color="primary">
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon
                              :color="data.usersmodul_modulcolour"
                              v-text="data.usersmodul_modulicon"
                            ></v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title
                              v-text="data.usersmodul_moduldesc.toUpperCase()"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-card>
                </v-col>
              </v-row>
              <div>
                made by love <v-icon color="red" small>favorite</v-icon
                ><br /><img src="../../public/logo.png" height="30" />
              </div>
            </template>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="white"
      dark
      dense
      class="elevation-1"
    >
      <v-toolbar-title class="ml-n8">
        <v-btn large text class="mr-5" @click="GetModules">
          <v-icon data-v-step="1" color="primary">{{
            $vars.V("icon_module")
          }}</v-icon>
        </v-btn>
        <span class="primary--text font-weight-bold">{{ menu_title }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- Additional Menu -->
      <template v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg">
        <v-menu
          open-on-hover
          offset-y
          v-for="data in filtermenu"
          :key="data.title"
        >
          <template v-slot:activator="{ on: submenu, attrs }">
            <v-btn
              text
              v-bind="attrs"
              v-on="submenu"
              class="text-capitalize primary--text"
              v-if="data.detail.length > 0"
            >
              {{ data.title }} <v-icon small>{{ $vars.V("icon_down") }}</v-icon>
            </v-btn>
            <v-btn
              text
              dark
              class="text-capitalize primary--text"
              v-else
              @click="GoTo(data.url, data.search)"
            >
              {{ data.title }}
            </v-btn>
          </template>
          <!-- Scroll when height more thank 500 px -->

          <v-list
            v-if="data.detail.length > 0"
            dense
            class="scrollmenu overflow-y-auto primary--text"
          >
            <v-list-item
              v-for="item in Sort(data.detail)"
              :key="item.title"
              @click="GoTo(item.url, data.search)"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <v-spacer></v-spacer>
      <!-- Main Icon App Bar -->
      <template>
        <!-- Button to force scree to full screen -->
        <v-icon
          small
          icon
          @click="Guidance"
          class="d-none d-sm-flex mx-1"
          color="primary"
          >{{ $vars.V("icon_question") }}</v-icon
        >
        <v-icon
          small
          icon
          @click="FullScreen"
          class="d-none d-sm-flex"
          color="primary"
          >{{ $vars.V("icon_fullscreen") }}</v-icon
        >
        <!-- Button to open notification -->
        <template v-if="countnotification > 0">
          <v-badge color="red" dot offset-x="6" offset-y="6">
            <v-icon
              small
              class="mx-1"
              color="primary"
              @click="OpenNotification"
              >{{ $vars.V("icon_notification") }}</v-icon
            >
          </v-badge>
        </template>
        <template v-else>
          <v-icon small class="mx-1" color="primary">{{
            $vars.V("icon_notification")
          }}</v-icon>
        </template>
      </template>
      <!-- Account Setting -->
      <v-menu offset-y>
        <template v-slot:activator="{ on: account, attrs }">
          <v-btn
            text
            class="ml-1"
            v-bind="attrs"
            v-on="account"
            @click="OpenSetting"
          >
            <v-avatar size="30px" item class="mr-2">
              <v-img :src="form.users_avatar_url"></v-img>
            </v-avatar>
            <span
              class="caption text-capitalize d-none d-sm-flex black--text font-weight-bold"
              ><v-icon small class="ml-1" color="black" v-if="setting">{{
                $vars.V("icon_down")
              }}</v-icon>
              <v-icon small class="ml-1" color="black" v-else>{{
                $vars.V("icon_up")
              }}</v-icon></span
            >
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
            v-for="data in dropdown_account_list"
            :key="data.id"
            ripple
            @click="DropdownAccount(data.id)"
          >
            <v-list-item-content>
              <v-list-item-title>{{ data.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <!-- Account Setting Dropdown -->
    <v-dialog
      v-model="confirm.dialog"
      :max-width="url_type === 'changepassword' ? 490 : 290"
      persistent
    >
      <v-card>
        <v-card-title class="primary white--text"
          >{{ confirm.title }}?</v-card-title
        >
        <v-card-text>
          <v-form
            enctype="multipart/form-data"
            ref="form_changepassword"
            lazy-validation
          >
            <v-row v-if="url_type === 'changepassword'">
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="form.old_password"
                  :type="form.old_password_show ? 'text' : 'password'"
                  required
                  :rules="form.old_password_rules"
                  label="Old Password *"
                  placeholder="Old Password"
                  id="old_password"
                  @keyup.enter="Loading"
                  :append-icon="
                    form.old_password_show ? 'mdi-eye' : 'mdi-eye-off'
                  "
                  @click:append="
                    form.old_password_show = !form.old_password_show
                  "
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
                :class="$vuetify.breakpoint.xs ? '' : 'mt-n5'"
              >
                <v-text-field
                  v-model="form.new_password"
                  :type="form.new_password_show ? 'text' : 'password'"
                  required
                  :rules="form.new_password_rules"
                  label="New Password *"
                  placeholder="New Password"
                  id="new_password"
                  @keyup.enter="Loading"
                  :append-icon="
                    form.new_password_show ? 'mdi-eye' : 'mdi-eye-off'
                  "
                  @click:append="
                    form.new_password_show = !form.new_password_show
                  "
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
                :class="$vuetify.breakpoint.xs ? '' : 'mt-n5'"
              >
                <v-text-field
                  v-model="form.confirm_password"
                  :type="form.confirm_password_show ? 'text' : 'password'"
                  required
                  :rules="form.confirm_password_rules"
                  label="Confirm Password *"
                  placeholder="Confirm Password"
                  id="confirm_password"
                  @keyup.enter="Loading"
                  :append-icon="
                    form.confirm_password_show ? 'mdi-eye' : 'mdi-eye-off'
                  "
                  @click:append="
                    form.confirm_password_show = !form.confirm_password_show
                  "
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="dialog"
            color="primary"
            :disabled="confirm.text === 'Ok' ? false : true"
            @click="Loading"
            >{{ confirm.text }}</v-btn
          >
          <v-btn @click="confirm.dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog.lock"
      scrollable
      persistent
      transition="dialog-bottom-transition"
      fullscreen
    >
      <v-card :img="lockscreenbg">
        <v-card-title><v-icon>lock</v-icon> {{ form.lockscreen }}</v-card-title>
        <v-card-text>
          <div class="text-center mt-2">
            <v-avatar size="100">
              <img alt="user" :src="form.users_avatar_url" />
            </v-avatar>
          </div>
          <v-row class="d-flex justify-center">
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="form.password"
                :type="form.password_show ? 'text' : 'password'"
                required
                :rules="form.password_rules"
                label="Password *"
                placeholder="Type Password and Enter"
                id="password"
                @keyup.enter="LockScreen"
                :append-icon="form.password_show ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="form.password_show = !form.password_show"
                filled
                solo
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Dialog List Notification -->
    <v-dialog
      v-model="dialog.notification"
      scrollable
      persistent
      transition="dialog-bottom-transition"
      max-width="490"
    >
      <v-card>
        <v-progress-linear
          indeterminate
          color="fourth"
          rounded
          v-if="loading.notification"
        ></v-progress-linear>
        <v-toolbar dark color="secondary" dense>
          <v-btn icon dark @click="dialog.notification = false">
            <v-icon>{{ $vars.V("icon_close") }}</v-icon>
          </v-btn>
          <v-toolbar-title
            >Your Notifications
            <span v-if="itemsnotification.length > 0"
              >({{ itemsnotification.length }})</span
            ></v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn icon dark @click="OpenNotification">
            <v-icon>{{ $vars.V("icon_refresh") }}</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-2 ml-n2">
          <template>
            <v-list three-line>
              <template v-for="item in itemsnotification">
                <v-divider
                  :key="`${item.notification_id}${item.notification_from}`"
                ></v-divider>
                <v-list-item :key="item.notification_id">
                  <v-list-item-avatar>
                    <v-img :src="item.notification_fromavaurl"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title
                      v-html="item.notification_from"
                    ></v-list-item-title>
                    <v-list-item-subtitle>
                      <v-btn x-small color="orange" dark>
                        {{ item.notification_modul }}
                      </v-btn>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{ item.notification_desc }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <small>{{
                      item.notification_cdate | moment("from", "now")
                    }}</small>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Dialog Guidance -->
    <v-dialog
      v-model="dialog.guidance"
      scrollable
      persistent
      transition="dialog-bottom-transition"
      max-width="690"
    >
      <v-card>
        <v-card-title class="secondary white--text"
          ><v-icon color="white">help</v-icon> Guidance : {{ $route.name }}
          <v-spacer></v-spacer>
          <v-btn
            variant="text"
            @click="dialog.guidance = false"
            depressed
            small
            fab
            color="secondary"
            ><v-icon dark>mdi-close</v-icon></v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-list dense v-if="guidanceversion.length > 0">
            <v-list-item
              v-for="rows in guidanceversion"
              :key="rows.guidance_id"
              link
              @click="OpenVersion(rows.guidance_id)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <v-icon>book</v-icon> Version:
                  {{ rows.guidance_version }} created by:
                  {{ rows.guidance_cuser }} -
                  {{ $functions.FormatDateTime(rows.guidance_cdate) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- End Dialog Guidance -->
    <!-- Dialog Guidance -->
    <v-dialog
      v-model="dialog.guidanceversion"
      scrollable
      persistent
      transition="dialog-bottom-transition"
      fullscreen
    >
      <v-card>
        <v-card-title class="primary white--text"
          ><v-icon color="white">help</v-icon> Menu : {{ $route.name }},
          Version: {{ form.version }} <v-spacer></v-spacer>
          <v-btn
            variant="text"
            @click="dialog.guidanceversion = false"
            depressed
            small
            fab
            color="secondary"
            ><v-icon dark>mdi-close</v-icon></v-btn
          ></v-card-title
        >
        <v-card-text>
          <quill-editor
            ref="guidanceremarks"
            v-model="guidanceremarks"
            :options="guidanceeditor"
          >
          </quill-editor>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- End Dialog Guidance -->
    <!-- Vue Chat Box -->
    <!-- Snackbar Notification -->
    <v-snackbar
      :timeout="snackbar.timeout"
      :color="snackbar.color"
      v-model="snackbar.dialog"
      rounded="pill"
      top
      style="z-index: 9999"
    >
      {{ snackbar.text }}
      <template>
        <v-btn text @click.native="snackbar.dialog = false">Close</v-btn>
      </template>
    </v-snackbar>
    <!-- Tour Guide Application -->
    <!-- <v-tour name="generaltour" :steps="toursteps"></v-tour> -->
    <template>
      <div style="position: absolute; top: 50px; z-index: 10">
        <beautiful-chat
          :participants="participants"
          :titleImageUrl="titleImageUrl"
          :onMessageWasSent="onMessageWasSent"
          :messageList="messageList"
          :newMessagesCount="newMessagesCount"
          :isOpen="isChatOpen"
          :close="closeChat"
          :open="openChat"
          :showEmoji="true"
          :showFile="true"
          :showEdition="true"
          :showDeletion="true"
          :showTypingIndicator="showTypingIndicator"
          :showLauncher="false"
          :showCloseButton="true"
          :colors="colors"
          :alwaysScrollToBottom="alwaysScrollToBottom"
          :disableUserListToggle="true"
          :messageStyling="messageStyling"
          @onType="handleOnType"
          @edit="editMessage"
          placeholder="Ada yg bisa Taka Bantu?"
        >
        </beautiful-chat>
      </div>
    </template>
    <app-search
      :dialog="searchdata.dialog"
      :search_title="searchdata.title"
      :src_id="searchdata.id"
      @close="searchdata.dialog = false"
      @clicked="GetValue"
    >
    </app-search>
  </div>
</template>
<script>
import menudata from '@/vars/menu.json'
export default {
  props: {
    drawer_menu_title: {
      type: String,
    },
    submenu_title: {
      type: String,
    },
  },
  data: () => ({
    showNavbar: true,
    offsetTop: 0,
    menudata: menudata,
    menu_title: "",
    menu_id: "",
    menu_limit: 60,
    local_core_users_id: null,
    dialog_users: false,
    dialog_users_card: "",
    confirm: {
      dialog: false,
      title: "",
      text: "Ok",
    },
    dialog_module: false,
    dropdown_account: false,
    dropdown_account_list: [
      { id: "N", title: "" },
      { id: "V", title: "" },
      { id: "R", title: "Reload Account" },
      //{ id: "B", title: "ChatBot" },
      { id: "myaccount", title: "My Account" },
      { id: "C", title: "Change Password" },
      { id: "S", title: "Lock Screen" },
      { id: "L", title: "Logout" },
    ],
    modules: [],
    url_type: "",
    lockscreenbg: "",
    form: {
      users_id: "",
      users_name: "",
      users_avatar_url: "",
      old_password: "",
      new_password: "",
      confirm_password: "",
      old_password_show: false,
      new_password_show: false,
      confirm_password_show: false,
      old_password_rules: [(v) => !!v || "Please fill Old Password"],
      new_password_rules: [(v) => !!v || "Please fill New Password"],
      confirm_password_rules: [(v) => !!v || "Please fill Confirm Password"],
      lockscreen: "Lock Screen",
      password: "",
      password_show: "",
      password_rules: [(v) => !!v || "Please fill Password"],
      search: false,
      search_text: "",
      version: "",
    },
    loading: {
      search: false,
      notification: false,
      module: false,
    },
    snackbar: {
      dialog: false,
      text: "",
      color: "primary",
      timeout: 3000,
    },
    dialog: {
      lock: false,
      notification: false,
      guidance: false,
      guidanceversion: false,
    },
    itemssearch: [],
    itemsnotification: [],
    toursteps: [
      {
        target: '[data-v-step="1"]', // We're using document.querySelector() under the hood
        header: {
          title: "Get Started",
        },
        content: `Click to open Modules`,
      },
    ],
    countnotification: 1,
    chat: {
      open: false,
    },
    participants: [], // the list of all the participant of the conversation. `name` is the user name, `id` is used to establish the author of a message, `imageUrl` is supposed to be the user avatar.
    titleImageUrl:
      "https://st3.depositphotos.com/8950810/17657/v/450/depositphotos_176577870-stock-illustration-cute-smiling-funny-robot-chat.jpg",
    messageList: [
      { type: "text", author: `me`, data: { text: `Say yes!` } },
      { type: "text", author: `user1`, data: { text: `No.` } },
    ], // the list of the messages to show, can be paginated and adjusted dynamically
    newMessagesCount: 0,
    isChatOpen: false, // to determine whether the chat window should be open or closed
    showTypingIndicator: "", // when set to a value matching the participant.id it shows the typing indicator for the specific user
    colors: {
      header: {
        bg: "#FF6600",
        text: "#ffffff",
      },
      launcher: {
        bg: "#FF6600",
      },
      messageList: {
        bg: "#ffffff",
      },
      sentMessage: {
        bg: "#001F48",
        text: "#ffffff",
      },
      receivedMessage: {
        bg: "#eaeaea",
        text: "#222222",
      },
      userInput: {
        bg: "#f4f7f9",
        text: "#565867",
      },
    }, // specifies the color scheme for the component
    alwaysScrollToBottom: false, // when set to true always scrolls the chat to the bottom when new events are in (new message, user starts typing...)
    messageStyling: true, // enables *bold* /emph/ _underline_ and such (more info at github.com/mattezza/msgdown)
    guidanceversion: [],
    guidanceremarks: "",
    guidanceeditor: {
      modules: {
        toolbar: "",
      },
    },
    searchdata: {
      dialog: false,
      id: "",
      title: "",
    },
    setting: false,
    idletimeout: 10 * 60 * 1000, // 3 menit dalam milidetik
    idletimer: null, // ID timer untuk logout
  }),
  created() {
    this.menu_title = this.drawer_menu_title;
    let data = this;
    setInterval(() => {
      data.ChangeTitle(); // call any function or end point
    }, 12000); // interval set to one sec.
    this.local_core_users_id = this.$functions.UsersID();
    if (this.local_core_users_id !== null) {
      this.FirstLoad();
    }
  },
  computed: {
    filtermenu() {
        // Memfilter data berdasarkan nilai 'modules'
        if (this.$route.meta.module && this.menudata[this.$route.meta.module]) {
          return this.menudata[this.$route.meta.module];
        }
        return [];
    },
  },
  mounted: function () {
    this.SetupIdleLogout();
  },
  beforeDestroy() {
    // Bersihkan event listener ketika komponen dihancurkan
    const events = ["mousemove", "keydown", "scroll", "click"];

    events.forEach((event) => {
      window.removeEventListener(event, this.ResetIdleTimer);
    });

    // Bersihkan timer
    clearTimeout(this.idletimer);
  },
  methods: {
    ChangeTitle() {
      if (this.menu_title !== "ASRINDA") {
        this.menu_title = "ASRINDA";
      } else {
        this.menu_title = this.drawer_menu_title;
      }
    },
    FirstLoad() {
      // Call Users Detail API
      this.UsersDetail();
      // Set Session Timeout
      // this.SessionTimeout()
      // Get Count Notification
      this.CountNotification();
      // Versionin
      this.dropdown_account_list[0].title = this.$functions.UsersID();
      this.dropdown_account_list[1].title =
        "Version " + this.$functions.AppVersion();
      // Set Background Lock Screeen
      this.lockscreenbg =
        this.$functions.AppConnection() + "/upload/files/background-login.png";
    },
    FullScreen() {
      let doc = window.document;
      let docEl = doc.documentElement;
      let requestFullScreen =
        docEl.requestFullscreen ||
        docEl.mozRequestFullScreen ||
        docEl.webkitRequestFullScreen ||
        docEl.msRequestFullscreen;
      let cancelFullScreen =
        doc.exitFullscreen ||
        doc.mozCancelFullScreen ||
        doc.webkitExitFullscreen ||
        doc.msExitFullscreen;
      if (
        !doc.fullscreenElement &&
        !doc.mozFullScreenElement &&
        !doc.webkitFullscreenElement &&
        !doc.msFullscreenElement
      ) {
        requestFullScreen.call(docEl);
      } else {
        cancelFullScreen.call(doc);
      }
    },
    Confirm(flag) {
      if (flag === "logout") {
        this.url_type = "logout";
        this.confirm.dialog = true;
        this.confirm.title = "Logout";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "changepassword") {
        this.url_type = "changepassword";
        this.confirm.dialog = true;
        this.confirm.title = "Change Password";
        setTimeout(function () {
          document.getElementById("old_password").focus();
        }, 500);
      }
    },
    Loading() {
      this.confirm.text = "Loading...";
      if (this.url_type === "logout") {
        this.LogOut();
      } else if (this.url_type === "changepassword") {
        if (this.$refs.form_changepassword.validate()) {
          this.ChangePassword();
        } else {
          this.confirm.text = "Ok";
        }
      }
    },
    LogOut() {
      let formdata = {
        url_type: "logout",
        users_id: this.$functions.UsersID(),
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteUsers"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              setTimeout(function () {
                localStorage.removeItem("local_core_users");
                localStorage.removeItem("local_core_users_detail");
                localStorage.removeItem("local_core_login");
                window.location.href = "/";
              }, 1000);
            } else {
              // this.Error(feedback[0].feedback)
              this.SnackBar(true, "error", feedback[0].feedback, 0);
              // alert(feedback[0].feedback)
            }
          } else {
            this.SnackBar(true, "error", "Logout is Failed", 0);
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 0);
        });
    },
    // Lock Password
    Lock() {
      localStorage.removeItem("local_core_login");
      this.dialog.lock = true;
      this.form.password = "";
      setTimeout(function () {
        document.getElementById("password").focus();
      }, 500);
    },
    // Open Lock Screen
    LockScreen() {
      //Call API Users
      this.form.lockscreen = "Loading...";
      let usersid = this.$functions.UsersID();
      let formdata = {
        url_type: "login",
        users_id: usersid,
        users_password: this.form.password,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteUsers"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              // Message
              this.SnackBar(true, "primary", "Login is Successfully", 3000);
              this.dialog.lock = false;
            } else {
              // this.Error(feedback[0].feedback)
              this.SnackBar(true, "error", feedback[0].feedback, 0);
              // alert(feedback[0].feedback)
            }
          } else {
            this.SnackBar(true, "error", "Login is Failed", 0);
          }
          this.form.lockscreen = "Lock Screen";
        })
        .catch((e) => {
          this.form.lockscreen = "Lock Screen";
          this.SnackBar(true, "error", e, 0);
        });
    },
    // Change Password User
    ChangePassword() {
      let formdata = {
        url_type: "changepassword",
        old_password: this.form.old_password,
        new_password: this.form.new_password,
        confirm_password: this.form.confirm_password,
        users_id: this.$functions.UsersID(),
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteUsers"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              this.form.old_password = "";
              this.form.new_password = "";
              this.form.confirm_password = "";
              this.SnackBar(true, "primary", "Change Password Successfully", 0);
            } else {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
              this.confirm.dialog = true;
            }
          } else {
            this.SnackBar(true, "error", "Login is Failed", 0);
            this.confirm.dialog = true;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 0);
          this.confirm.dialog = true;
        });
    },
    // UsersDetail Local Storage
    UsersDetail() {
      let users_detail = JSON.parse(
        localStorage.getItem("local_core_users_detail")
      );
      this.form.users_avatar_url = users_detail[0].users_avatar_url;
      this.form.users_name = users_detail[0].users_name;
      this.form.users_divisiondesc = users_detail[0].users_divisiondesc;
      this.form.users_email = users_detail[0].users_email;
      this.form.users_phone_1 = users_detail[0].users_phone_1;
    },
    // Module GoTo
    GoTo(url, search) {
      if (search !== undefined) {
        this.OpenSearch(search);
      } else {
        if (this.$route.name !== url) {
          this.$router.push({ name: url });
        } else {
          this.dialog_module = false;
        }
      }
    },
    // Dropdown Account
    DropdownAccount(id) {
      if (id === "L") {
        this.Confirm("logout");
      } else if (id === "R") {
        this.ReloadAccount();
      } else if (id === "S") {
        this.Lock();
      } else if (id === "C") {
        this.Confirm("changepassword");
      } else if (id === "V" || id === "N") {
        console.log(id);
      } else if (id === "B") {
        this.openChat();
      } else {
        this.$router.push({ name: id });
      }
    },
    // Open Snackbar Notification
    SnackBar(dialog, color, text, timeout) {
      this.snackbar = {
        dialog: dialog,
        color: color,
        text: text,
        timeout: timeout,
      };
      if (color === "error") {
        this.confirm.dialog = false;
        this.confirm.text = "Ok";
      }
    },
    // Search Bar inside module
    SearchMenu() {
      this.loading.search = true;
      let formdata = {
        datavalue: this.form.search_text,
        menu_type: "menu",
        menu_access: "Y",
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListMenu"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.itemssearch = feedback;
            } else {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            }
          } else {
            this.itemssearch = feedback;
            this.SnackBar(true, "error", this.$functions.NoData(), 0);
          }
          this.loading.search = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 0);
          this.loading.search = false;
        });
    },
    // Get Notification List Unread
    OpenNotification() {
      this.dialog.notification = true;
      this.loading.notification = true;
      let formdata = {
        notification_to: this.local_core_users_id,
        order_by: "notification_id",
        order_type: "DESC",
        limit: "10",
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListNotification"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.itemsnotification = feedback;
            } else {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            }
          } else {
            this.itemsnotification = feedback;
            this.SnackBar(true, "error", this.$functions.NoData(), 0);
          }
          this.loading.notification = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 0);
          this.loading.notification = false;
        });
    },
    // Get Total Unread Notification
    CountNotification() {
      let formdata = {
        url_type: "count",
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteNotification"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.countnotification = feedback[0].feedback_count_data;
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 0);
        });
    },
    Guidance() {
      this.dialog.guidance = true;
      let formdata = {
        guidance_menu: this.$route.name,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListGuidance"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.guidanceversion = feedback;
            } else {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 0);
        });
    },
    Sort(data) {
      return data.slice().sort(function (a, b) {
        return a.title > b.title ? 1 : -1;
      });
    },
    sendMessage(text) {
      if (text.length > 0) {
        this.newMessagesCount = this.isChatOpen
          ? this.newMessagesCount
          : this.newMessagesCount + 1;
        this.onMessageWasSent({ author: "me", type: "text", data: { text } });
      }
    },
    onMessageWasSent(message) {
      // called when the user sends a message
      this.messageList = [...this.messageList, message];
    },
    openChat() {
      // called when the user clicks on the fab button to open the chat
      this.isChatOpen = true;
      this.newMessagesCount = 0;
    },
    closeChat() {
      // called when the user clicks on the botton to close the chat
      this.isChatOpen = false;
    },
    handleScrollToTop() {
      // called when the user scrolls message list to top
      // leverage pagination for loading another page of messages
    },
    handleOnType() {
      console.log("Emit typing event");
    },
    editMessage(message) {
      const m = this.messageList.find((m) => m.id === message.id);
      m.isEdited = true;
      m.data.text = message.data.text;
    },
    OpenVersion(version) {
      this.dialog.guidanceversion = true;
      let formdata = {
        guidance_menu: this.$route.name,
        guidance_version: version,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListGuidance"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.form.version = feedback[0].guidance_version;
              this.guidanceremarks = feedback[0].guidance_remarks;
            } else {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 0);
        });
    },
    OpenSearch(flag) {
      if (flag === "policy") {
        this.searchdata.id = "policy_row";
      } else if (flag === "claim") {
        this.searchdata.id = "claim_row";
      }
      this.searchdata.dialog = true;
      this.searchdata.title = flag;
    },
    GetValue(value) {
      if (this.searchdata.title === "policy") {
        this.$router
          .push({
            name: "policydetail",
            params: { id: this.$functions.EncodeUrl(value) },
          })
          .catch(() => {});
      } else if (this.searchdata.title === "claim") {
        this.$router
          .push({
            name: "claimdetail",
            params: { id: this.$functions.EncodeUrl(value) },
          })
          .catch(() => {});
      } else if (this.searchdata.title === "quotation") {
        this.$router
          .push({
            name: "quotationdetail",
            params: { id: this.$functions.EncodeUrl(value) },
          })
          .catch(() => {});
      }
    },
    OpenSetting() {
      this.setting = !this.setting;
    },
    ReloadAccount() {
      let formdata = {
        users_id: this.$functions.UsersID(),
        flag: "myogis",
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListUsersShort"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              localStorage.removeItem("local_core_users_detail");
              localStorage.setItem(
                "local_core_users_detail",
                JSON.stringify(feedback)
              );
              this.SnackBar(true, "success", "Reload Account is Success", 0);
              setTimeout(function () {
                location.reload();
              }, 1000);
            } else {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            }
          } else {
            this.SnackBar(true, "error", "Reload Account is Failed", 0);
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 0);
        });
    },
    GetModules() {
      this.dialog_module = true;
      this.loading.module = true;
      let userstype = this.$functions.UsersType();
      let formdata = {
        usersmodul_userstype: userstype,
        usersmodul_userstypedata: userstype,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListUsersModul"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.modules = feedback;
            } else {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            }
          }
          this.loading.module = false;
        })
        .catch((e) => {
          this.loading.module = false;
          this.SnackBar(true, "error", e, 0);
        });
    },
    // Fungsi untuk reset idle timer
    ResetIdleTimer() {
      // Hapus timer sebelumnya
      clearTimeout(this.idletimer);

      // Setel ulang timer
      this.idletimer = setTimeout(this.LogOut, this.idletimeout);
    },
    // Tambahkan event listener untuk aktivitas pengguna
    SetupIdleLogout() {
      const events = ["mousemove", "keydown", "scroll", "click"];

      events.forEach((event) => {
        window.addEventListener(event, this.ResetIdleTimer);
      });

      // Mulai timer pertama kali
      this.ResetIdleTimer();
    },
  },
};
</script>
<style>
.scrollmenu {
  max-height: 400px;
}
.scrollmenu::-webkit-scrollbar {
  width: 5px;
}
/* Track */
.scrollmenu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.scrollmenu::-webkit-scrollbar-thumb {
  background: #001f48;
  border-radius: 10px;
}

/* Handle on hover */
.scrollmenu::-webkit-scrollbar-thumb:hover {
  background: #001f48;
}
.sc-header--img {
  width: 75px;
  height: 75px;
}
</style>
