<template>
  <v-col cols="12" sm="12" md="12">
    <v-progress-linear
      indeterminate
      color="fourth"
      rounded
      v-if="loading.detail"
    ></v-progress-linear>
    <span class="font-weight-bold">Total Reinsurance : {{ items.length }}</span>
    <v-card
      v-for="(item, index) in items"
      :key="item.reinstrans_profileid || index"
      class="my-5"
    >
      <v-card-text>
        <v-row>
          <v-col md="3">
            <v-text-field
              v-model="item.reinstrans_docno"
              label="Docno"
              placeholder="0"
              background-color="readonly"
              readonly
            ></v-text-field>
          </v-col>
          <v-col md="9">
            <v-text-field
              v-model="item.reinstrans_profiledesc"
              label="Reinsurance"
              placeholder="0"
              background-color="readonly"
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2" md="2" class="mt-n8">
            <app-number
              v-model="item.reinstrans_rate"
              label="RATE"
              placeholder="RATE"
              backgroundColor="readonly"
              :decimal="15"
              :readonly="false"
              required
              solo-inverted
              outlined
            >
            </app-number>
          </v-col>
          <v-col cols="12" sm="2" md="2" class="mt-n8">
            <!-- <vuetify-money
              v-model="item.reinstrans_finalrate"
              :options="formatamount15"
              label="BROKER RE RATE"
              placeholder="BROKER RE RATE"
              background-color="numeric"
              :properties="properties"
            /> -->
            <app-number
              v-model="item.reinstrans_finalrate"
              label="BROKER RE RATE"
              placeholder="BROKER RE RATE"
              backgroundColor="readonly"
              :decimal="15"
              :readonly="false"
              required
              solo-inverted
              outlined
            >
            </app-number>
          </v-col>
          <v-col cols="12" sm="2" md="2" class="mt-n8">
            <v-select
              v-model="item.reinstrans_rateunit"
              :items="reinstrans_rateunit"
              item-text="code_desc"
              item-value="code_id"
              label="Unit"
              placeholder="Unit"
              required
              :rules="reinstrans_rateunit_rules"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="2" md="2" class="mt-n8">
            <!-- <vuetify-money
              v-model="item.reinstrans_ricomm"
              :options="format_pct"
              label="RI COM (%)"
              placeholder="RI COM (%)"
              :background-color="
                item.reinstrans_status == 'Request' ? numeric : readonly
              "
              :properties="properties"
              :readonly="item.reinstrans_status == 'Request' ? false : true"
            /> -->
            <app-number
              v-model="item.reinstrans_ricomm"
              label="RI COM (%)"
              placeholder="RI COM (%)"
              backgroundColor="readonly"
              :decimal="15"
              :readonly="false"
              required
            >
            </app-number>
          </v-col>
          <v-col cols="12" sm="2" md="2" class="mt-n8">
            <!-- <vuetify-money
              v-model="item.reinstrans_ricommamount"
              :options="formatamount15"
              label="RI COM (Amount)"
              placeholder="RI COM (Amount)"
              background-color="numeric"
              :properties="properties"
              :readonly="item.reinstrans_status == 'Request' ? false : true"
            /> -->
            <!-- <v-money
              v-model="item.reinstrans_ricommamount"
              label="RI COM (Amount)"
            ></v-money> -->
            <app-number
              v-model="item.reinstrans_ricommamount"
              label="RI COM (Amount)"
              placeholder="RI COM (Amount)"
              backgroundColor="readonly"
              :decimal="15"
              :readonly="false"
              required
            >
            </app-number>
          </v-col>
          <v-col cols="12" sm="2" md="2" class="mt-n8">
            <app-number
              v-model="item.reinstrans_share"
              label="Estimate Share"
              placeholder="Estimate Share"
              backgroundColor="readonly"
              :decimal="15"
              :readonly="false"
              required
            >
            </app-number>
          </v-col>
          <v-col cols="12" sm="2" md="2" class="mt-n8">
            <!-- <vuetify-money
              v-model="item.reinstrans_finalshare"
              :options="formatamount15"
              label="Final Share"
              placeholder="Final Share"
              :properties="properties"
              :readonly="item.reinstrans_status == 'Request' ? false : true"
            /> -->
            <app-number
              v-model="item.reinstrans_finalshare"
              label="Final Share"
              placeholder="Final Share"
              backgroundColor="readonly"
              :decimal="15"
              :readonly="false"
              required
            >
            </app-number>
          </v-col>
          <v-col cols="12" sm="10" md="10" class="mt-n8">
            <v-text-field
              v-model="item.reinstrans_remarks"
              label="Remarks"
              placeholder="Remarks"
              :readonly="item.reinstrans_status == 'Request' ? false : true"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="mt-n5">
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          small
          title="Reject"
          @click="Approval('reject', item)"
          outlined
          :disabled="item.reinstrans_status == 'Request' ? false : true"
        >
          <v-icon left> mdi-emoticon-sad</v-icon>
          Reject
        </v-btn>
        <v-btn
          color="fourth"
          title="Approve"
          @click="Approval('approve', item)"
          outlined
          small
          :disabled="item.reinstrans_status == 'Request' ? false : true"
          class="mr-2"
        >
          <v-icon left>mdi-emoticon-excited</v-icon>
          Approve
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar
      v-model="snackbar.dialog"
      :timeout="snackbar.timeout"
      color="fourth"
      rounded="pill"
      top
      style="z-index: 9999"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          @click.native="remarks.dialog = true"
          small
          v-if="snackbar.color === 'error'"
          >Open</v-btn
        >
        <v-btn dark text @click.native="snackbar.dialog = false" small
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-dialog v-model="confirm.dialog" max-width="490" hide-overlay persistent>
      <v-card>
        <v-card-title class="primary white--text"
          >{{ confirm.title }}?</v-card-title
        >
        <v-card-text></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="dialog"
            color="success"
            :disabled="confirm.text === 'Ok' ? false : true"
            @click="Process"
            >{{ confirm.text }}
          </v-btn>
          <v-btn color="error" @click="confirm.dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>
<script>
export default {
  props: {
    id: String,
  },
  data: () => ({
    items: [],
    confirm: {
      dialog: false,
      text: "",
    },
    snackbar: {
      dialog: false,
      color: "success",
      text: "",
      timeout: 3000,
    },
    loading: {
      detail: false,
    },
    format_pct: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 18,
      precision: 15,
    },
    formatamount15: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 30,
      precision: 15,
    },
    properties: {
      reverse: true,
    },

    reinstrans_rateunit: [
      { code_id: "C", code_desc: "Percent (%)" },
      { code_id: "M", code_desc: "Permil (%o)" },
    ],
    reinstrans_rateunit_rules: [(v) => !!v || "Please fill Rate Unit"],
  }),
  created() {
    this.List(this.id);
  },
  methods: {
    List(id) {
      //   this.Access();
      this.items = [];
      if (id !== "") {
        this.loading.detail = true;
        let formdata = {
          reinstrans_docno: id,
          order_by: "reinstrans_docno",
          order_type: "ASC",
          limit: this.limit,
        };
        let param = this.$functions.ParamPOST(formdata);
        this.$axios
          .post(this.$functions.UrlPOST("apiListReinsTrans"), param, {
            headers: {
              "Content-Type": "text/plain; charset=ISO-8859-1",
            },
          })
          .then((response) => {
            let feedback = response.data;
            if (feedback.length > 0) {
              if (feedback[0].feedback !== "Y") {
                this.SnackBar(true, "error", feedback[0].feedback, 0);
              } else {
                this.items = feedback;
                console.log(this.items);
              }
            } else {
              this.items = feedback;
              // this.SnackBar(true, 'error', this.$functions.NoData(), 0)
            }
            this.loading.detail = false;
          })
          .catch((e) => {
            this.SnackBar(true, "error", e, 3000);
            this.loading.detail = false;
          });
      }
    },
    GetChipColor(status) {
      if (status === "Request") return "cyan";
      if (status === "Reject") return "red";
      if (status === "Approve") return "teal";
      return "grey";
    },
    GetChipIcon(status) {
      if (status === "Request") return "mdi-alert-decagram";
      if (status === "Reject") return "mdi-close-circle";
      if (status === "Approve") return "mdi-check-decagram";
      return "grey";
    },

    SnackBar(dialog, color, text, timeout) {
      this.snackbar = {
        dialog: dialog,
        color: color,
        text: text,
        timeout: timeout,
      };
      if (color === "error") {
        this.confirm.dialog = false;
        this.confirm.text = "Ok";
      }
    },
    // Approval logic
    Approval(action, item) {
      if (!["approve", "reject"].includes(action)) {
        this.SnackBar(true, "error", "Invalid action", 3000);
        return;
      }
      if (!item) {
        this.SnackBar(true, "error", "No item to process", 3000);
        return;
      }
      this.confirm = {
        dialog: true,
        title: `Confirm ${action.charAt(0).toUpperCase() + action.slice(1)}`,
        item: item,
        text: "Ok",
        action: action,
      };
    },
    Process() {
      const item = this.confirm.item;
      const action = this.confirm.action;
      const approvalData = {
        reinstrans_profileid: item.reinstrans_profileid,
        reinstrans_docno: item.reinstrans_docno,
        reinstrans_share: String(item.reinstrans_share) || "0",
        reinstrans_finalshare: String(item.reinstrans_finalshare) || "0",
        reinstrans_ricomm: String(item.reinstrans_ricomm) || "0",
        reinstrans_remarks: item.reinstrans_remarks || "",
        reinstrans_rate: String(item.reinstrans_rate) || "0",
        reinstrans_rateunit: item.reinstrans_rateunit || "",
        reinstrans_finalrate: String(item.reinstrans_finalrate) || "0",
        reinstrans_status: action == "approve" ? "A" : "J",
        url_type: "approval",
      };
      this.confirm.text = "Loading";
      const param = this.$functions.ParamPOST(approvalData);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteReinsTrans"), param, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          const feedback = response.data;

          if (feedback.length > 0 && feedback[0].feedback === "Y") {
            this.SnackBar(true, "success", `${action} successful`, 3000);
            this.List(this.id); // Refresh list
          } else {
            const errorMessage = feedback[0]?.feedback || "Approval failed";
            this.SnackBar(true, "error", errorMessage, 3000);
          }
          this.confirm.text = "Ok";
        })
        .catch((e) => {
          this.SnackBar(true, "error", e.message || "Error occurred", 3000);
        })
        .finally(() => {
          this.confirm.dialog = false;
        });
    },
  },
};
</script>
