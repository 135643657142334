<template>
  <div id="app-attachmentreins">
    <v-progress-linear
      indeterminate
      color="fourth"
      rounded
      v-if="loading.page"
    ></v-progress-linear>
    <div v-if="access.read === 0" class="text-center font-weight-black">
      {{ $vars.V("txt_no_access") }}
    </div>
    <div v-else>
      <form>
        <v-row class="mt-5">
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              v-model="form.reinsuranse_profileid"
              label="Reinsurance ID"
              prepend-inner-icon="list"
              background-color="readonly"
              :readonly="true"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="8" md="8">
            <v-text-field
              v-model="form.reinsuranse_profiledesc"
              label="Reinsurance"
              background-color="readonly"
              :readonly="true"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="pt-0 pb-0">
            <v-combobox
              v-model="form.reinsuranse_pic_marketing"
              chips
              clearable
              label="TO"
              multiple
              class="no-dropdown-icon mt-0 pt-0"
              @input="emitData"
              style="font-size: 14px"
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click="select"
                  @click:close="remove(item)"
                >
                  <strong>{{ item }}</strong>
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
          <v-col cols="12" class="pt-0 pb-0">
            <v-combobox
              v-model="form.reinsuranse_pic_marketing_cc"
              chips
              clearable
              label="Cc"
              multiple
              class="no-dropdown-icon mt-0 pt-0"
              @input="emitData"
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click="select"
                  @click:close="removecc(item)"
                >
                  <strong>{{ item }}</strong>
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
          <v-col cols="12 mt-0 pt-0">
            <div class="d-flex align-center mb-3">
              <span class="mt-5 mr-3">Indonesia</span>
              <v-switch
                v-model="language"
                label="English"
                color="info"
                value="E"
                hide-details
                @change="emitData"
              ></v-switch>
            </div>
            <quill-editor
              ref="ticket_remarks"
              v-model="form.reinstrans_template"
              @input="emitData"
            ></quill-editor>
          </v-col>
        </v-row>
        <v-card-title class="ml-n4">
          <v-btn
            :color="selected.length === items.length ? 'fourth' : ''"
            @click="SelectAllPage"
            class="mr-2"
            title="select all page"
            small
            >{{ $vars.V("txt_select_all_page") }}
          </v-btn>

          <v-btn @click="List(refno, modul)" class="mr-2" title="Refresh" small
            ><v-icon>{{ $vars.V("icon_refresh") }}</v-icon>
          </v-btn>
          <v-btn
            color="primary"
            title="search"
            v-if="btn_search === false"
            @click="btn_search = true"
            small
          >
            <v-icon>{{ $vars.V("icon_search") }}</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            @click:append="btn_search = false"
            label="Search"
            single-line
            hide-details
            v-if="btn_search"
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :value="selected"
          :headers="headers"
          :items="items"
          :search="search"
          @input="Check($event)"
          item-key="attachment_id"
          show-select
          class="elevation-1"
          :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'first_page',
            lastIcon: 'last_page',
            prevIcon: 'keyboard_arrow_left',
            nextIcon: 'keyboard_arrow_right',
          }"
          dense
          :loading="loading.detail"
          loading-text="Loading... Please wait"
          :items-per-page="itemsPerPage"
        >
          <template v-slot:item.attachment_path="{ item }">
            <v-icon @click="$functions.OpenURL(item.attachment_path_url)"
              >download</v-icon
            >
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon
              small
              @click="Edit(item.attachment_id)"
              :disabled="access.edit === 0 ? true : false"
              title="edit"
            >
              {{ $vars.V("icon_edit") }}
            </v-icon>
          </template>
        </v-data-table>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modul: String,
    refno: String,
    profileid: String,
    profiledesc: String,
    picmarketing: String,
    subclass: String,
    quolang: String,
  },
  data: () => ({
    language: "",
    class_email_quo_eng: "",
    class_email_quo_ind: "",
    url_type: "add",
    activePanel: [0],
    readonly: true,
    itemsPerPage: -1,
    access: {
      read: 0,
      add: 0,
      edit: 0,
      delete: 0,
    },
    remarks: {
      feedback: "",
      dialog: false,
    },
    snackbar: {
      dialog: false,
      color: "success",
      text: "",
      timeout: 3000,
    },
    confirm: {
      dialog: false,
      title: "",
      text: "Ok",
    },
    loading: {
      page: true,
      detail: true,
    },
    dialog: {
      detail: false,
    },
    formatamount: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 15,
      precision: 0,
    },
    formatrate: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 5,
      precision: 2,
    },
    properties: {
      reverse: true,
    },
    selected: [],
    items: [],
    btn_search: false,
    search: "",
    headers: [
      { text: "ID", value: "attachment_id" },
      { text: "FILE", value: "attachment_title", width: "70%" },
      { text: "Extention", value: "attachment_ext" },
      { text: "Path", value: "attachment_path" },
    ],
    form: {
      attachment_id: "",
      attachment_modul: "",
      attachment_refno: "",
      reinsuranse_profileid: "",
      reinsuranse_profiledesc: "",
      reinsuranse_pic_marketing: [],
      reinsuranse_pic_marketing_cc: [],
      reinstrans_template: "",
    },
  }),
  created() {
    this.form.reinsuranse_profileid = this.profileid;
    this.form.reinsuranse_profiledesc = this.profiledesc;
    this.List(this.refno, this.modul);
    this.GetToCCEmail(this.profileid, this.subclass);
    this.GetTemplate(this.subclass);
  },
  watch: {
    profileid(newVal) {
      this.form.reinsuranse_profileid = newVal;
      this.List(this.refno, this.modul);
      this.GetToCCEmail(this.form.reinsuranse_profileid, this.subclass);
      this.GetTemplate(this.subclass);
      console.log("masuk");
    },
    profiledesc(newVal) {
      this.form.reinsuranse_profiledesc = newVal;
    },
    picmarketing(newVal) {
      this.form.reinsuranse_pic_marketing = newVal
        .split(",")
        .map((email) => email.trim());
    },
    items: function () {
      this.initializeSelection();
    },
    language(newLang) {
      if (newLang === "E") {
        this.form.reinstrans_template = this.class_email_quo_eng;
      } else {
        this.form.reinstrans_template = this.class_email_quo_ind;
      }
    },
  },
  methods: {
    Access() {
      let modul = "attachment";
      let formdata = {
        menu_id: modul,
        users_id: this.$functions.UsersID(),
        order_by: "menu_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListMenuUser"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let priv = response.data;
          if (priv.length > 0) {
            this.access.read = priv[0].usersmenu_read;
            this.access.add = priv[0].usersmenu_add;
            this.access.edit = priv[0].usersmenu_edit;
            this.access.delete = priv[0].usersmenu_delete;
            if (priv[0].usersmenu_read === 0) {
              this.SnackBar(
                true,
                "error",
                this.$functions.NoPriviledge(modul),
                0
              );
            }
          } else {
            this.SnackBar(
              true,
              "error",
              this.$functions.NoPriviledge(modul),
              0
            );
          }
          this.loading.page = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 0);
          this.loading.page = false;
        });
    },
    SnackBar(dialog, color, text, timeout) {
      this.snackbar = {
        dialog: dialog,
        color: color,
        text: text,
        timeout: timeout,
      };
      if (color === "error") {
        this.confirm.dialog = false;
        this.confirm.text = "Ok";
      }
    },
    Variable(flag, position) {
      return this.$functions.Variable(flag, position);
    },
    SelectAllPage() {
      this.selected = this.selected === this.items ? [] : this.items;
      this.$emit("selected-changed", this.selected);
    },
    Check(value) {
      this.selected = value;
      this.$emit("selected-changed", this.selected);
    },
    initializeSelection() {
      this.selected = [...this.items];
      this.$emit("selected-changed", this.selected);
    },
    emitData() {
      this.$emit("update:reinstrans_template", this.form.reinstrans_template);
      this.$emit(
        "update:reinsuranse_pic_marketing",
        this.form.reinsuranse_pic_marketing
      );
      this.$emit(
        "update:reinsuranse_pic_marketing_cc",
        this.form.reinsuranse_pic_marketing_cc
      );
      this.$emit("update:language", this.language);
    },
    Loading() {
      this.confirm.text = "Loading...";
      if (this.url_type === "add" || this.url_type === "edit") {
        this.Write();
      } else if (this.url_type === "delete") {
        this.Delete(this.form.attachment_id);
      } else if (this.url_type === "multidelete") {
        this.MultiProcess("delete");
      }
    },
    List(id, modul) {
      this.Access();
      this.items = [];
      if (id !== "") {
        this.loading.detail = true;
        let formdata = {
          attachment_refno: id,
          attachment_modul: modul,
          order_by: "attachment_refno",
          order_type: "ASC",
          limit: this.limit,
        };
        let param = this.$functions.ParamPOST(formdata);
        this.$axios
          .post(this.$functions.UrlPOST("apiListAttachment"), param, {
            headers: {
              "Content-Type": "text/plain; charset=ISO-8859-1",
            },
          })
          .then((response) => {
            let feedback = response.data;
            if (feedback.length > 0) {
              if (feedback[0].feedback !== "Y") {
                this.SnackBar(true, "error", feedback[0].feedback, 0);
              } else {
                this.items = feedback;
              }
            } else {
              this.items = feedback;
              // this.SnackBar(true, 'error', this.$functions.NoData(), 0)
            }
            console.log("masuk");
            this.loading.detail = false;
          })
          .catch((e) => {
            this.SnackBar(true, "error", e, 3000);
            this.loading.detail = false;
          });
      }
    },
    GetToCCEmail(profileid, subclassid) {
      if (profileid !== "") {
        this.loading.detail = true;
        let formdata = {
          profileclasspic_id: profileid,
          profileclasspic_subclassid: subclassid.substring(0, 2),
          order_by: "profileclasspic_id",
          order_type: "ASC",
          limit: this.limit,
        };
        let param = this.$functions.ParamPOST(formdata);
        this.$axios
          .post(this.$functions.UrlPOST("apiListProfileClassPIC"), param, {
            headers: {
              "Content-Type": "text/plain; charset=ISO-8859-1",
            },
          })
          .then((response) => {
            let feedback = response.data;
            if (feedback.length > 0) {
              if (feedback[0].feedback !== "Y") {
                this.SnackBar(true, "error", feedback[0].feedback, 0);
              } else {
                this.form.reinsuranse_pic_marketing_cc =
                  feedback[0].profileclasspic_mkt_email
                    .split(",")
                    .map((email) => email.trim());
              }
            } else {
              this.SnackBar(true, "error", this.$functions.NoData(), 0);
            }
            this.loading.detail = false;
          })
          .catch((e) => {
            this.SnackBar(true, "error", e, 3000);
            this.loading.detail = false;
          });
      }
    },
    GetTemplate(subclassid) {
      const formData = {
        class_id: subclassid.substring(0, 2),
      };
      let param = this.$functions.ParamPOST(formData);
      this.$axios
        .post(this.$functions.UrlPOST("apiListClass"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback == "Y") {
              this.class_email_quo_eng = feedback[0].class_email_quo_eng;
              this.class_email_quo_ind = feedback[0].class_email_quo_ind;
              if (this.quolang == "E") {
                this.form.reinstrans_template = feedback[0].class_email_quo_eng;
              } else {
                this.form.reinstrans_template = feedback[0].class_email_quo_ind;
              }
              this.emitData();
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
          this.loading.detail = false;
        });
    },
    remove(item) {
      this.form.reinsuranse_pic_marketing.splice(
        this.form.reinsuranse_pic_marketing.indexOf(item),
        1
      );
    },
    removecc(item) {
      this.form.reinsuranse_pic_marketing_cc.splice(
        this.form.reinsuranse_pic_marketing_cc.indexOf(item),
        1
      );
    },
  },
};
</script>
<style>
.no-dropdown-icon .v-input__append-inner .v-icon {
  display: none;
}
.v-text-field input,
textarea {
  font-size: 14px !important;
}
</style>
