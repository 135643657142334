import Vue from 'vue'
import Router from 'vue-router'

//Modul Absence
import ModAbsence from '@/views/modul/mod_absence/absence.vue'
//Modul Account
import ModAccount from '@/views/modul/mod_account/account.vue'
//Modul Agencybanner 
import ModAgencybanner from '@/views/modul/mod_agencybanner/agencybanner.vue'
//Modul Apimodul
import ModApimodul from '@/views/modul/mod_apimodul/apimodul.vue'
//Modul Bank
import ModBank from '@/views/modul/mod_bank/bank.vue'
//Modul Branch
import ModBranch from '@/views/modul/mod_branch/branch.vue'
//Modul Branchparent
import ModBranchparent from '@/views/modul/mod_branchparent/branchparent.vue'
//Modul Campaign
import ModCampaign from '@/views/modul/mod_campaign/campaign.vue'
//Modul CauseLoss
import ModCauseLoss from '@/views/modul/mod_causeloss/causeloss.vue'
//Modul Channel
import ModChannel from '@/views/modul/mod_channel/channel.vue'
//Modul Class
import ModClass from '@/views/modul/mod_class/class.vue'
//Modul Class
import ModClassBussiness from '@/views/modul/mod_classbussiness/classbussiness.vue'
//Modul Code
import ModCode from '@/views/modul/mod_code/code.vue'
//Modul Currency
import ModCurrency from '@/views/modul/mod_currency/currency.vue'
//Modul Dashboard
import ModDashboard from '@/views/modul/mod_dashboard/dashboard.vue'
//Modul Department
import ModDepartment from '@/views/modul/mod_department/department.vue'
//Modul Deductible
import ModDeductible from '@/views/modul/mod_deductible/deductible.vue'
//Modul Division
import ModDivision from '@/views/modul/mod_division/division.vue'
//Modul Directorate
import ModDirectorate from '@/views/modul/mod_directorate/directorate.vue'
//Modul Dummy
import ModDummy from '@/views/modul/mod_dummy/dummy.vue'
//Modul Feeduty
import ModFeeduty from '@/views/modul/mod_feeduty/feeduty.vue'
//Modul General Table
import ModGeneralTable from '@/views/modul/mod_generaltable/generaltable.vue'
//Modul Grade
import ModGrade from '@/views/modul/mod_grade/grade.vue'
//Modul Instype
import ModInstype from '@/views/modul/mod_instype/instype.vue'
//Modul Interest
import ModInterest from '@/views/modul/mod_interest/interest.vue'
//Modul Increment
import ModIncrement from '@/views/modul/mod_increment/increment.vue'
//Modul Indemnity
import ModIndemnity from '@/views/modul/mod_indemnity/indemnity.vue'
//Modul Logs
import ModLogs from '@/views/modul/mod_logs/logs.vue'
//Modul LossItem
import ModLossItem from '@/views/modul/mod_lossitem/lossitem.vue'
//Modul NatureClaim
import ModNatureClaim from '@/views/modul/mod_natureclaim/natureclaim.vue'
// Modul MapUpload
import ModMapUpload from '@/views/modul/mod_mapupload/mapupload.vue'
//Modul Menu
import ModMenu from '@/views/modul/mod_menu/menu.vue'
//Modul NotFound
import ModNotFound from '@/views/modul/mod_notfound/notfound.vue'
//Modul Auto Numbering
import ModNumbering from '@/views/modul/mod_numbering/numbering.vue'
//Modul Policy Production
import ModPolicy from '@/views/modul/mod_policy/policy.vue'
//Modul Policy Production PDF
// import ModPolicyPrint from '@/views/modul/mod_policyproduction/pdf.vue'
//Modul Policytype
import ModPolicytype from '@/views/modul/mod_policytype/policytype.vue'
//Modul Productint
import ModProductint from '@/views/modul/mod_productint/productint.vue'
//Modul Acceptance
import ModAcceptance from '@/views/modul/mod_acceptance/acceptance.vue'
//Modul Riskcov
import ModRiskcov from '@/views/modul/mod_riskcov/riskcov.vue'
//Modul Reason
import ModReason from '@/views/modul/mod_reason/reason.vue'
//Modul Segment
import ModSegment from '@/views/modul/mod_segment/segment.vue'
//Modul Setting
import ModSetting from '@/views/modul/mod_setting/setting.vue'
//Modul SLCategory
import ModSLCategory from '@/views/modul/mod_slcategory/slcategory.vue'
//Modul SubClass
import ModSubClass from '@/views/modul/mod_subclass/subclass.vue'
//Modul Test JMA
import ModTestAJK from '@/views/modul/mod_dummy/test_ajk.vue'
//Modul Tokens
import ModTokens from '@/views/modul/mod_tokens/tokens.vue'
//Modul Users
import ModUsers from '@/views/modul/mod_users/users.vue'
//Modul Usersact
import ModUsersact from '@/views/modul/mod_usersact/usersact.vue'
//Modul Userstype
import ModUserstype from '@/views/modul/mod_userstype/userstype.vue'
//Modul Vouchertype
import ModVouchertype from '@/views/modul/mod_vouchertype/vouchertype.vue'
//Modul Wording
import ModWording from '@/views/modul/mod_wording/wording.vue'
//Modul MyAccount
import ModMyAccount from '@/views/modul/mod_myaccount/myaccount.vue'
// Modul Mapping
import ModMapping from '@/views/modul/mod_mapping/mapping.vue'
// Modul Country
import ModCountry from '@/views/modul/mod_country/country.vue'
//Modul Hint
import ModHint from '@/views/modul/mod_hint/hint.vue'
//Modul Report
import ModReport from '@/views/modul/mod_report/report.vue'
//Modul Report
import ModReportProduction from '@/views/modul/mod_report/report_production.vue'
//Modul Report
import ModReportOutstanding from '@/views/modul/mod_report/report_outstanding.vue'
//Modul Report
import ModReportAccrued from '@/views/modul/mod_report/report_accrued.vue'
//Modul Upload
import ModUpload from '@/views/modul/mod_upload/upload.vue'
//Modul Upload Profile
import ModUploadProfile from '@/views/modul/mod_upload/upload_profile.vue'
//Modul Upload Policy
import ModUploadPolicy from '@/views/modul/mod_upload/upload_policy.vue'
//Modul TableLimit
import ModTableLimit from '@/views/modul/mod_tablelimit/tablelimit.vue'
//Modul Riskloss
import ModRiskloss from '@/views/modul/mod_riskloss/riskloss.vue'
//Modul Riskloss
import ModRateTab from '@/views/modul/mod_ratetab/ratetab.vue'
//Modul Claim
import ModClaim from '@/views/modul/mod_claim/claim.vue'
//Modul Finance
import ModFinance from '@/views/modul/mod_finance/finance.vue'
//Modul Company Account
import ModCompAcc from '@/views/modul/mod_compacc/compacc.vue'
//Modul Receipt Request
import ModReceiptRequest from '@/views/modul/mod_receiptrequest/receiptrequest.vue'
//Modul Payment Request
import ModPaymentRequest from '@/views/modul/mod_paymentrequest/paymentrequest.vue'
//Modul Company Account
import ModCompanyAccount from '@/views/modul/mod_companyaccount/companyaccount.vue'
// Modul Rate Discount
import ModRateDiscount from '@/views/modul/mod_ratediscount/ratediscount.vue'
// Modul Reisunrance Rate
import ModReinsRate from '@/views/modul/mod_reinsrate/reinsrate.vue'
//Modul Upload Reinsurance Rate
import ModUploadReinsRate from '@/views/modul/mod_upload/upload_reinsrate.vue'
//Modul Upload Reinsurance Type
import ModReinsType from '@/views/modul/mod_reinstype/reinstype.vue'
//Modul Expense
import ModExpense from '@/views/modul/mod_expense/expense.vue'
//Modul Letter
import ModLetter from '@/views/modul/mod_letter/letter.vue'
//Modul Reinstreaty
import ModReinstreaty from '@/views/modul/mod_reinstreaty/reinstreaty.vue'
// //Modul Dashboard Management
import ModDashboardManagement from '@/views/modul/mod_dashboard/dashboard_management.vue'
//Modul Profile Type
import ModProfileType from '@/views/modul/mod_profiletype/profiletype.vue'
//Modul General Affair
import ModGeneralAffair from '@/views/modul/mod_generalaffair/generalaffair.vue'
//Modul TicketType
import ModTicketType from '@/views/modul/mod_tickettype/tickettype.vue'
//Modul Profile Company Group
import ModProfileCGroup from '@/views/modul/mod_profilecgroup/profilecgroup.vue'
//Modul Profile Line Of Business
import ModProfileLOB from '@/views/modul/mod_profilelob/profilelob.vue'
//Modul Profile
import ModProfile from '@/views/modul/mod_profile/profile.vue'
//Modul Guidance
import ModGuidance from '@/views/modul/mod_guidance/guidance.vue'
//Modul Audit
import ModAudit from '@/views/modul/mod_audit/audit.vue'
//Modul Users
import ModModul from '@/views/modul/mod_modul/modul.vue'
//Modul Leave Category
import ModLeaveCategory from '@/views/modul/mod_leavecategory/leavecategory.vue'
//Modul Overtime
import ModOvertime from '@/views/modul/mod_overtime/overtime.vue'
//Modul Quotation
import ModFacultativeDashboard from '@/views/modul/mod_quotation/facultativedashboard.vue'
import ModTreatyDashboard from '@/views/modul/mod_quotation/treatydashboard.vue'
import ModFacultative from '@/views/modul/mod_quotation/facultative.vue'
import ModTreaty from '@/views/modul/mod_quotation/treaty.vue'
//Modul Production
import ModProduction from '@/views/modul/mod_production/production.vue'
Vue.use(Router)

export default new Router({
  // hashbang: false,
  // history: true,
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
  	{
      path: '/404',
      alias: '*',
      component : ModNotFound
    },
  	//Modul Dashboard
    {
      path: '/',
      name: 'dashboard',
      components: {
        default: ModDashboard
    	},
    },
    //Routes menu
    {
      path: '/absence',
      name: 'absence',
      components: {
        default: ModAbsence
      }
    },
    {
      path: '/account',
      name: 'account',
      components: {
        default: ModAccount
      },
      meta: { module: 'setting' },
    },
    {
      path: '/agencybanner',
      name: 'agencybanner',
      components: {
        default: ModAgencybanner
      }
    },
    {
      path: '/apimodul',
      name: 'apimodul',
      components: {
        default: ModApimodul
      },
      meta: { module: 'setting' },
    },
    {
      path: '/bank',
      name: 'bank',
      components: {
        default: ModBank
      },
      meta: { module: 'setting' },
    },
    {
      path: '/branch',
      name: 'branch',
      components: {
        default: ModBranch
      },
      meta: { module: 'setting' },
    },
    {
      path: '/branchparent',
      name: 'branchparent',
      components: {
        default: ModBranchparent
      },
      meta: { module: 'setting' },
    },
    {
      path: '/campaign',
      name: 'campaign',
      components: {
        default: ModCampaign
      },
      meta: { module: 'setting' },
    },
    {
      path: '/causeloss',
      name: 'causeloss',
      components: {
        default: ModCauseLoss
      },
      meta: { module: 'setting' },
    },
    {
      path: '/channel',
      name: 'channel',
      components: {
        default: ModChannel
      },
      meta: { module: 'setting' },
    },
    {
      path: '/class',
      name: 'class',
      components: {
        default: ModClass
      },
      meta: { module: 'setting' },
    },
    {
      path: '/classbussiness',
      name: 'classbussiness',
      components: {
        default: ModClassBussiness
      },
      meta: { module: 'setting' },
    },
    {
      path: '/code',
      name: 'code',
      components: {
        default: ModCode
      },
      meta: { module: 'setting' },
    },
    {
      path: '/currency',
      name: 'currency',
      components: {
        default: ModCurrency
      },
      meta: { module: 'setting' },
    },
    {
      path: '/department',
      name: 'department',
      components: {
        default: ModDepartment
      },
      meta: { module: 'employee' },
    },
    {
      path: '/deductible',
      name: 'deductible',
      components: {
        default: ModDeductible
      },
      meta: { module: 'setting' },
    },
    {
      path: '/division',
      name: 'division',
      components: {
        default: ModDivision
      },
      meta: { module: 'employee' },
    },
    {
      path: '/directorate',
      name: 'directorate',
      components: {
        default: ModDirectorate
      },
      meta: { module: 'employee' },
    },
    {
      path: '/dummy',
      name: 'dummy',
      components: {
        default: ModDummy
      }
    },
    {
      path: '/feeduty',
      name: 'feeduty',
      components: {
        default: ModFeeduty
      },
      meta: { module: 'setting' },
    },
    {
      path: '/grade',
      name: 'grade',
      components: {
        default: ModGrade
      },
      meta: { module: 'employee' },
    },
    {
      path: '/generaltable',
      name: 'generaltable',
      components: {
        default: ModGeneralTable
      },
      meta: { module: 'setting' },
    },
    {
      path: '/instype',
      name: 'instype',
      components: {
        default: ModInstype
      },
      meta: { module: 'setting' },
    },
    {
      path: '/interest',
      name: 'interest',
      components: {
        default: ModInterest
      },
      meta: { module: 'setting' },
    },
    {
      path: '/increment',
      name: 'increment',
      components: {
        default: ModIncrement
      }
    },
    {
      path: '/logs',
      name: 'logs',
      components: {
        default: ModLogs
      },
      meta: { module: 'setting' },
    },
    {
      path: '/lossitem',
      name: 'lossitem',
      components: { 
        default: ModLossItem
      },
      meta: { module: 'setting' },
    },
    {
      path: '/natureclaim',
      name: 'natureclaim',
      components: {
        default: ModNatureClaim
      },
      meta: { module: 'setting' },
    },
    {
      path: '/mapupload',
      name: 'mapupload',
      components: {
        default: ModMapUpload
      },
      meta: { module: 'setting' },
    },
    {
      path: '/menu',
      name: 'menu',
      components: {
        default: ModMenu
      },
      meta: { module: 'setting' },
    },
    {
      path: '/numbering',
      name: 'numbering',
      components: {
        default: ModNumbering
      },
      meta: { module: 'setting' },
    },
    {
      path: '/policy',
      name: 'policy',
      components: {
        default: ModPolicy
      },
      meta: { module: 'policy' },
    },
    {
      path: '/policydetail/:id',
      name: 'policydetail',
      components: {
        default: ModPolicy
      },
      meta: { module: 'certificate' },
    },
    // {
    //   path: '/policy/print',
    //   name: 'policyprint',
    //   components: {
    //     default: ModPolicyPrint
    //   }
    // },
    {
      path: '/policytype',
      name: 'policytype',
      components: {
        default: ModPolicytype
      },
      meta: { module: 'setting' },
    },
    {
      path: '/productint',
      name: 'productint',
      components: {
        default: ModProductint
      }
    },
    {
      path: '/acceptance',
      name: 'acceptance',
      components: {
        default: ModAcceptance
      }
    },
    {
      path: '/riskcov',
      name: 'riskcov',
      components: {
        default: ModRiskcov
      },
      meta: { module: 'setting' },
    },
    {
      path: '/reason',
      name: 'reason',
      components: {
        default: ModReason
      },
      meta: { module: 'setting' },
    },
    {
      path: '/segment',
      name: 'segment',
      components: {
        default: ModSegment
      },
      meta: { module: 'setting' },
    },
    {
      path: '/setting',
      name: 'setting',
      components: {
        default: ModSetting
      },
      meta: { module: 'setting' },
    },
    {
      path: '/slcategory',
      name: 'slcategory',
      components: {
        default: ModSLCategory
      },
      meta: { module: 'setting' },
    },
    {
      path: '/subclass',
      name: 'subclass',
      components: {
        default: ModSubClass
      },
      meta: { module: 'setting' },
    },
    {
      path: '/test_ajk',
      name: 'test_ajk',
      components: {
        default: ModTestAJK
      }
    },
    {
      path: '/tokens',
      name: 'tokens',
      components: {
        default: ModTokens
      }
    },
    {
      path: '/users',
      name: 'users',
      components: {
        default: ModUsers
      },
      meta: { module: 'setting' },
    },
    {
      path: '/usersact',
      name: 'usersact',
      components: {
        default: ModUsersact
      }
    },
    {
      path: '/userstype',
      name: 'userstype',
      components: {
        default: ModUserstype
      },
      meta: { module: 'setting' },
    },
    {
      path: '/vouchertype',
      name: 'vouchertype',
      components: {
        default: ModVouchertype
      },
      meta: { module: 'setting' },
    },
    {
      path: '/compacc',
      name: 'compacc',
      components: {
        default: ModCompAcc
      },
      meta: { module: 'setting' },
    },
    {
      path: '/wording',
      name: 'wording',
      components: {
        default: ModWording
      },
      meta: { module: 'setting' },
    },
    {
      path: '/myaccount',
      name: 'myaccount',
      components: {
        default: ModMyAccount
      }
    },
    {
      path: '/mapping',
      name: 'mapping',
      components: {
          default: ModMapping
      },
      meta: { module: 'setting' },
    },
    {
      path: '/country',
      name: 'country',
      components: {
          default: ModCountry
      },
      meta: { module: 'setting' },
    },
    {
      path: '/indemnity',
      name: 'indemnity',
      components: {
        default: ModIndemnity
      }
    },
    {
      path: '/hint',
      name: 'hint',
      components: {
        default: ModHint
      },
      meta: { module: 'setting' },
    },
    {
      path: '/report',
      name: 'report',
      components: {
        default: ModReport
      },
      meta: { module: 'report' },
    },
    {
      path: '/upload',
      name: 'upload',
      components: {
        default: ModUpload
      },
      meta: { module: 'upload' },
    },
    {
      path: '/upload/profile',
      name: 'uploadprofile',
      components: {
        default: ModUploadProfile
      },
      meta: { module: 'upload' },
    },
    {
      path: '/upload/policy',
      name: 'uploadpolicy',
      components: {
        default: ModUploadPolicy
      },
      meta: { module: 'upload' },
    },
    {
      path: '/tablelimit',
      name: 'tablelimit',
      components: {
      default: ModTableLimit
      },
      meta: { module: 'setting' },
    },
    {
      path: '/riskloss',
      name: 'riskloss',
      components: {
        default: ModRiskloss
      },
      meta: { module: 'setting' },
    },
    {
      path: '/ratetab',
      name: 'ratetab',
      components: {
        default: ModRateTab
      },
      meta: { module: 'setting' },
    },
    {
      path: '/claim',
      name: 'claim',
      components: {
      default: ModClaim
      },
      meta: { module: 'claim' },
    },
    {
      path: '/claimdetail/:id',
      name: 'claimdetail',
      components: {
      default: ModClaim
      },
      meta: { module: 'claim' },
    },
    {
      path: '/finance',
      name: 'finance',
      components: {
        default: ModFinance
      },
      meta: { module: 'finance' },
    },
    {
      path: '/receiptrequest',
      name: 'receiptrequest',
      components: {
        default: ModReceiptRequest
      },
      meta: { module: 'finance' },
    },

    {
      path: '/paymentrequest',
      name: 'paymentrequest',
      components: {
        default: ModPaymentRequest
      },
      meta: { module: 'finance' },
    },

    {
      path: '/companyaccount',
      name: 'companyaccount',
      components: {
        default: ModCompanyAccount
      },
      meta: { module: 'companyaccount' },
    },
    {
      path: '/ratediscount',
      name: 'ratediscount',
      components: {
        default: ModRateDiscount
      },
      meta: { module: 'setting' },
    },
    {
      path: '/reinsrate',
      name: 'reinsrate',
      components: {
        default: ModReinsRate
      },
      meta: { module: 'setting' },
    },
    {
      path: '/upload/reinsrate',
      name: 'uploadreinsrate',
      components: {
        default: ModUploadReinsRate
      },
      meta: { module: 'upload' },
    },
    {
      path: '/reinstype',
      name: 'reinstype',
      components: {
        default: ModReinsType
      },
      meta: { module: 'setting' },
    },
    {
      path: '/expense',
      name: 'expense',
      components: {
        default: ModExpense
      },
      meta: { module: 'setting' },
    },
    {
      path: '/letter',
      name: 'letter',
      components: {
        default: ModLetter
      },
      meta: { module: 'setting' },
    },
    {
      path: '/reinstreaty',
      name: 'reinstreaty',
      components: {
        default: ModReinstreaty
      },
      meta: { module: 'setting' },
    },
    //Modul Dashboard Management
    {
      path: '/dashboardmanagement',
      name: 'dashboardmanagement',
      components: {
        default: ModDashboardManagement
      },
    },
    //Modul profile type
    {
      path: '/profiletype',
      name: 'profiletype',
      components: {
        default: ModProfileType
      },
      meta: { module: 'setting' },
    },
    //Modul General Affair
    {
      path: '/generalaffair',
      name: 'generalaffair',
      components: {
        default: ModGeneralAffair
      },
    },
    {
      path: '/tickettype',
      name: 'tickettype',
      components: {
        default: ModTicketType
      },
      meta: { module: 'setting' },
    },
    //Modul profile company group
    {
      path: '/profilecgroup',
      name: 'profilecgroup',
      components: {
        default: ModProfileCGroup
      },
      meta: { module: 'setting' },
    },
    //Modul profile line of business
    {
      path: '/profilelob',
      name: 'profilelob',
      components: {
        default: ModProfileLOB
      },
      meta: { module: 'setting' },
    },
    //Modul profile type
    {
      path: '/profile',
      name: 'profile',
      components: {
        default: ModProfile
      },
      meta: { module: 'setting' },
    },
    //Modul Guidance
    {
      path: '/guidance',
      name: 'guidance',
      components: {
        default: ModGuidance
      },
    },
    //Modul Audit
    {
      path: '/audit',
      name: 'audit',
      components: {
        default: ModAudit
      },
    },
    {
      path: '/report/production',
      name: 'reportproduction',
      components: {
        default: ModReportProduction
      },
      meta: { module: 'report' },
    },
    {
      path: '/report/outstanding',
      name: 'reportoutstanding',
      components: {
        default: ModReportOutstanding
      },
      meta: { module: 'report' },
    },
    {
      path: '/report/accrued',
      name: 'reportaccrued',
      components: {
        default: ModReportAccrued
      },
      meta: { module: 'report' },
    },
    {
      path: '/employee',
      name: 'employee',
      components: {
        default: ModUsers
      },
      meta: { module: 'employee' },
    },
    {
      path: '/modul',
      name: 'modul',
      components: {
        default: ModModul
      },
      meta: { module: 'setting' },
    },
    {
      path: '/leavecategory',
      name: 'leavecategory',
      components: {
        default: ModLeaveCategory
      },
      meta: { module: 'employee' },
    },
    {
      path: '/overtime',
      name: 'overtime',
      components: {
        default: ModOvertime
      },
      meta: { module: 'employee' },
    },
    //Modul Quotation
    // {
    //   path: '/quotation',
    //   name: 'quotation',
    //   components: {
    //     default: ModQuotation
    //   },
    //   meta: { module: 'quotation' },
    // },
    {
      path: '/quotation/facultative',
      name: 'facultativedashboard',
      components: {
        default: ModFacultativeDashboard
      },
      meta: { module: 'facultative' },
    },
    {
      path: '/quotation/facultative/new',
      name: 'quotationfacultative',
      components: {
        default: ModFacultative
      },
      meta: { module: 'quotation' },
    },
    {
      path: '/quotation/treaty',
      name: 'treatydasboard',
      components: {
        default: ModTreatyDashboard
      },
      meta: { module: 'treaty' },
    },
    {
      path: '/quotation/treaty/new',
      name: 'quotationtreaty',
      components: {
        default: ModTreaty
      },
      meta: { module: 'quotation' },
    },
    //Modul Quotation Search
    {
      path: '/quotationdetail/:id',
      name: 'quotationdetail',
      components: {
        default: ModFacultative
      },
      meta: { module: 'quotation' },
    },
    // Modul Production
    {
      path: '/production',
      name: 'production',
      components: {
        default: ModProduction
      },
      meta: { module: 'production' },
    },
  ]
})