<template>
  <v-dialog
    v-model="dialogprint"
    persistent
    transition="dialog-bottom-transition"
    max-width="490"
  >
    <app-print-detail
      v-model="form.dialog"
      :modul="form.modul"
      :id="form.id"
      :title="form.title"
      :doc="form.doc"
    ></app-print-detail>
    <v-card>
      <v-toolbar dark color="fourth" dense>
        <v-toolbar-title>Print Document</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click.stop="dialogprint = false">
          <v-icon>cancel</v-icon>
        </v-btn>
      </v-toolbar>
      <template>
        <v-list dense>
          <v-list-item-group>
            <template v-for="(item, index) in items">
              <v-list-item
                :key="item.doc"
                @click="Open(item.doc, modul, id, item.text)"
              >
                <template>
                  <v-list-item-avatar>
                    <v-icon class="grey lighten-1" dark>
                      {{ item.icon }}
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text"></v-list-item-title>

                    <v-list-item-subtitle
                      v-text="item.subtitle"
                    ></v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action> </v-list-item-action>
                </template>
              </v-list-item>

              <v-divider
                v-if="index < items.length - 1"
                :key="index"
              ></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
      </template>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    value: Boolean,
    modul: String,
    id: String,
    refid: String,
  },
  data: () => ({
    url_type: "add",
    items: [],
    form: {
      dialog: false,
      modul: "",
      id: "",
      title: "",
      doc: "",
    },
  }),
  watch: {
    dialogprint: function (newdata) {
      if (newdata === true) {
        if (this.modul === "acceptance") {
          this.items = [
            {
              doc: "sp3",
              text: "SP3",
              icon: "description",
              subtitle: "Surat Persetujuan Prinsip Penjaminan",
            },
            {
              doc: "certificate",
              text: "Certificate",
              icon: "description",
              subtitle: "Sertifikat Penjaminan",
            },
            {
              doc: "committee",
              text: "Committee Materials",
              icon: "description",
              subtitle: "Bahan Komite",
            },
          ];
        }
        if (this.modul === "facultative") {
          this.items = [
            {
              doc: "creditnote",
              text: "Credit Note",
              icon: "description",
              subtitle: "Credit Note",
            },
            {
              doc: "inwardfacultative",
              text: "Inward Facultative",
              icon: "description",
              subtitle: "Reinsurance Brokers Slip Inward",
            },
            {
              doc: "outwardfacultative",
              text: "Outward Facultative",
              icon: "description",
              subtitle: "Reinsurance Brokers Slip Outward",
            },
          ];
        } else if (this.modul === "quotation") {
          if (this.refid === "U") {
            this.items = [
              {
                doc: "public_offering",
                text: "Quotation General",
                icon: "description",
                subtitle: "Surat Penawaran Umum",
              },
            ];
          } else if (this.refid === "K") {
            this.items = [
              {
                doc: "special_offering",
                text: "Quotation Special",
                icon: "description",
                subtitle: "Surat Penawaran Khusus",
              },
            ];
          }
          if (this.items.length === 1) {
            this.Open(
              this.items[0].doc,
              this.modul,
              this.id,
              this.items[0].text
            );
          }
        }
      }
    },
  },
  computed: {
    dialogprint: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    Open(doc, modul, id, title) {
      this.form.dialog = true;
      this.form.doc = doc;
      this.form.modul = modul;
      this.form.id = id;
      this.form.title = title;
    },
  },
};
</script>
